@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    background: linear-gradient(180deg, #F5F5FD 0%, #FFFFFF 69.79%);
    font-family: 'Inter', sans-serif;

    .alterfont {
        font-family: 'Rubik', sans-serif;
    }

    .p-button-icon-only {

        .p-button-label {
            display: none;
        }
    }

    .smooth-tag {
        position: relative;
        padding: 0 .5rem;
        margin-left: .35rem;

        i {
            position: relative;
            z-index: 1;
            color: var(--global-tag-value);
            font-weight: 500;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            transform: skewX(-10deg);
            border-radius: 5px;
            background: var(--global-highlight-color);
            z-index: 0;
        }
    }

    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        background: transparent;
    }

    .p-sidebar-mask {

        &.account-sidebar {

            .p-sidebar {
                width: 80vw;
                background: var(--top-search-accent);
            }
        }

        &.hooked {
            visibility: hidden;
        }

        &.mobile-menu {

            .p-sidebar {
                width: 80vw;
                background: var(--top-search-accent);

                .search {
                    right: 55px;

                    .p-input-icon-left {
                        width: 100%;
                    }

                    .p-inputtext {
                        font-size: 1rem;
                        color: var(--global-tag-border-color);
                    }
                }
            }

            .p-accordion-header-link {
                font-size: 1.2rem;
            }

            .sub-accordion {
                font-size: 1.2rem;
            }
        }
    }

    .p-sidebar {
        background: var(--global-filled-page);
        background: var(--account-background);

        .p-sidebar-header {
            padding-right: 16px;
        }

        .p-sidebar-close-icon.pi {
            color: var(--surface-200);
        }

        .p-sidebar-content {
            padding: 0;

            .p-accordion-tab {
                margin-bottom: 0;
                background: transparent;
                border-radius: 0;

                &.p-accordion-tab-active {

                    .p-accordion-header {

                        a.p-accordion-header-link {
                            background: var(--mobile-nav-link-bgd);
                            border-color: transparent;
                            color: var(--blue-50);

                            &::after {
                                background: var(--mobile-nav-separator);
                            }
                        }
                    }
                }

                .p-accordion-header.directLink {

                    a.p-accordion-header-link {

                        .p-accordion-toggle-icon {
                            display: none;
                        }
                    }
                }

                .p-accordion-header, .p-accordion-header a {
                    background: transparent;
                    border-radius: 0;
                    border: none;
                    position: relative;
                    font-family: 'Rubik', sans-serif;
                    font-weight: 400;
                    text-transform: uppercase;

                    a.p-accordion-header-link {
                        color: var(--mobile-nav-text);
                        transition: all .4s ease-in-out;

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background: var(--mobile-nav-separator);
                        }
                    }

                    &:not(.p-highlight):not(.p-disabled):hover {
                        a {
                            color: var(--mobile-nav-link-text);
                        }
                    }

                    a {
                        .pi {
                            position: absolute;
                            right: 16px;
                            font-size: 14px;
                        }

                        &:focus, &:active {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }

                .p-accordion-content {
                    background: var(--mobile-nav-link-bgd);
                    border: none;
                    border-radius: 0;
                    padding: 0px;

                    .sub-accordion {
                        height: 48px;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        color: var(--mobile-nav-link-text);
                        position: relative;

                        &.current {
                            background: var(--mobile-nav-link-active-bgd);
                            color: var(--mobile-nav-link-active);
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background: var(--mobile-subnav-separator);
                            opacity: .5;
                        }

                        &:last-child {

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .ripple {
        position: relative;
        overflow: hidden;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-position: center;
            transition: background 1.2s;
        }

        &:hover {
            &::after {
                background: rgba(255, 255, 255, .03) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, .05) 1%) center/15000%;
            }
        }

        &:active {
            &::after {
                background-color: rgba(255, 255, 255, .05);
                background-size: 100%;
                transition: background 0s;
            }
        }
    }

    .p-buttonset .p-button:first-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-buttonset .p-button:not(:last-child) {
        border-right: 0;
    }

    .p-buttonset .p-button:last-of-type {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .p-buttonset {

        .p-button {
            transition: all 0.25s ease-in-out;
        }

        .p-button-sm {
            padding: 7px 12px;
        }

        .p-button:focus, .p-button:active {
            outline: none;
            box-shadow: none;
        }

        .p-button:hover {
            cursor: pointer;
        }
    }

    .p-dropdown-panel {

        @media screen and (max-width: 601px) {
            left: 8px !important;
            right: 8px;
        }
    }

    .p-dropdown-panel .p-dropdown-items {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        border-radius: 8px;
        overflow: hidden;

        .p-dropdown-item {
            white-space: normal;
        }
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        font-weight: normal;
        color: var(--bluegray-400);
        font-size: 11px;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        background: var(--blue-50);
        color: var(--blue-600);
    }

    .p-dropdown:not(.p-disabled):hover {
        border-color: var(--blue-400);
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: var(--blue-400);
    }

    .play_btn {
        width: 52px;
        height: 52px;
        background-image: url('./assets/img/play_btn.png');
        background-size: cover;
        background-position: center;
    }

    .store_btn {
        transition: all 0.45s ease-in-out;
        width: 180px;
        height: 52px;
        background-size: cover;
        background-position: center;

        &:hover {
            cursor: pointer;
            opacity: .78;
        }

        &.googleplay {
            background-image: url('./assets/img/googleplay.png');
        }

        &.appstore {
            background-image: url('./assets/img/appstore.png');
        }
    }

    .pub-panel {

        .p-sidebar-header {
            position: absolute;
            width: 72px;
            right: 8px;
            z-index: 5;
        }
    }

    .sidebar-spinner {

        .p-progress-spinner-svg {
            animation: p-progress-spinner-color 2s linear infinite;

            @keyframes p-progress-spinner-color {
                100%,
                0% {
                    stroke: var(--blue-400);
                }
                40% {
                    stroke: var(--blue-500);
                }
                66% {
                    stroke: var(--blue-400);
                }
                80%,
                90% {
                    stroke: var(--blue-500);
                }
            }
        }
    }
}
