.layout-desktop {

    .player {

        .container {

            &.stiched {

                &.stiched-bottom {

                    .content {

                        .side {

                            &>div {
                                transform: translateY(-150px);
                            }
                        }
                    }

                    .col {

                        .profile {
                            transform: translateY(-45%);
                        }
                    }
                }

                .col {

                    .info {

                        .player-subs {
                            right: -15px;
                        }
                    }

                    .profile {
                        position: fixed;
                        top: 28px;
                        transition: all .4s linear;
                    }
                }

                .content {

                    .side {

                        &>div {
                            position: fixed;
                            top: 50px;
                            transition: all .4s linear;

                            .player-summary {

                                .title {
                                    color: var(--player-summary-title);
                                }

                                .grid {

                                    .grid-item {

                                        .label {
                                            .tooltip-trigger {
                                                background: var(--player-summary-grid-label-bgd);
                                                color: var(--player-summary-grid-label-color);
                                            }
                                        }

                                        .value {
                                            span {
                                                color: var(--player-summary-grid-value-color);
                                                border-color: var(--player-summary-grid-value-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .profile {

        .info {
            padding-left: 20px;
        }
    }
}

.layout-mobile {

    .profile {

        .info {
            .player-subs {
                // position: relative;
                top: unset;
                right: unset;
                flex-direction: row;
                align-items: flex-end;
                padding-left: 0;
                padding-right: 0;
                margin-top: 50px;
                margin-bottom: 6px;

                .player-subs_title {
                    margin-right: 12px;
                    font-size: 13px;
                    letter-spacing: 0;
                    color: var(--bluegray-400);
                    font-weight: normal;
                }

                .player-subs_value {
                    position: absolute;
                    width: 27px;
                    right: 0;
                    bottom: -6px;
                    left: unset;
                }

                .player-subs_icon {
                    height: 27px;

                    .pi {
                        font-size: 27px;
                    }
                }
            }
        }
    }
}

.profile {
    transition: all 0.4s ease;
    position: relative;

    .avatar-shield {
        margin-top: 20px;
    }

    .avatar-skeleton {
        margin-left: 20px;
        margin-top: 26px;

        .p-skeleton {
            background: #f6f9fc;

            &::after {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(133, 174, 215, 0.09), rgba(255, 255, 255, 0));
            }
        }
    }

    .info {
        margin-top: 58px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: .6px;
        padding-left: 48px;
        color: var(--bluegray-900);

        .player-subs {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            position: absolute;
            top: 32px;
            right: 5px;
            z-index: 50;
            //background: linear-gradient(to bottom, #fff 85%, transparent);
            background: #fff;
            padding: 4px 10px;
            border-radius: 6px;
            transition: all .3s linear;

            .player-subs_title {
                margin-top: 6px;
                font-weight: 300;
                font-size: 10px;
                text-transform: lowercase;
                text-align: center;
                color: var(--bluegray-700);
                padding-bottom: 3px;
            }

            .player-subs_value {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                bottom: -14px;
                left: 0;
                right: 0;
                z-index: 2;
                font-size: 14px;
                font-weight: 500;
                color: var(--bluegray-800);

                &>div:first-child {
                    margin-bottom: 4px;
                }
            }

            .player-subs_icon {
                margin-left: 12px;
                height: 34px;
                position: relative;
                margin-left: 0;
                text-align: center;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 20px;
                    background: linear-gradient(to top, #fff, transparent);
                }

                .pi {
                    font-size: 34px;
                    color: var(--yellow-300);
                }
            }
        }

        .p-skeleton {
            background: #f6f9fc;

            &::after {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(133, 174, 215, 0.09), rgba(255, 255, 255, 0));
            }
        }

        .name {
            font-size: 21px;
            opacity: .95;

            .p-skeleton {
                margin-bottom: 4px;
            }
        }

        .surname {
            font-size: 18px;
        }

        .age {
            font-size: 13px;
            margin-top: 6px;
            font-weight: normal;
            color: var(--bluegray-400);
            text-transform: none;
            letter-spacing: 0;
        }

        @media screen and (max-width: 375px) {
            .name {
                font-size: 16px;
            }

            .surname {
                font-size: 20px;
            }
        }

        .p-tag.sub {
            font-size: 11px;
            padding: 3px 7px 1px;
            line-height: 11px;
            font-weight: normal;
            text-transform: none;
            margin-top: 8px;
            transition: all .35s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .78;
            }

            .pi {
                font-size: 11px;
            }

            &.negative {
                background: var(--gray-50);
                color: var(--gray-500);
            }

            &.positive {
                background: var(--global-tag-background);
                color: var(--global-tag-color);
            }
        }
    }

    .squads {
        padding-left: 20px;
        margin-top: 20px;
        padding-bottom: 30px;

        .section {

            .label {
                font-size: 14px;
                color: var(--bluegray-700);
                position: relative;
                padding: 11px 0;

                &::after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    content: '';
                    background: linear-gradient(to left, transparent, var(--blue-100));
                    bottom: 0;
                }
            }

            .p-dropdown {
                width: 100%;
                background: transparent;
                border: none;
                position: relative;

                .p-dropdown-label {
                    padding-left: 0;
                }

                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    content: '';
                    background: linear-gradient(to left, transparent, var(--blue-100));
                    bottom: 0;
                }
            }

            .items {
                margin-bottom: 14px;

                .item {
                    display: flex;
                    align-items: center;
                    height: 44px;
                    background: #fcfcff;
                    padding: 0 14px 0 0;
                    position: relative;
                    transition: all 0.4s ease-in-out;
                    margin-left: -15px;
                    padding-left: 15px;
                    // border-top-right-radius: 10px;
                    // border-bottom-right-radius: 10px;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                        background: linear-gradient(to right, transparent, #ecf4ff);
                    }

                    &.selected {
                        background: linear-gradient(to right, transparent, #ecf4ff);
                    }

                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        content: '';
                        background: linear-gradient(to right, transparent, var(--blue-50));
                        bottom: 0;
                    }

                    .emblem {
                        margin-right: 10px;
                    }

                    span {
                        font-size: 13px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
