.install-cta {
    position: fixed;
    background: rgba(255, 255, 255, 0.11);
    backdrop-filter: blur(2px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.7s ease-in-out;

    &.visible {
        opacity: 1;
        visibility: visible;

        &.shown {

            .install-cta_card {
                animation: spaceInUp .75s forwards;
            }
        }

        &.gone {

            .install-cta_card {
                animation: spaceOutUp 1s forwards;
            }
        }
    }

    .install-cta_card {
        width: 300px;
        height: 480px;
        background: #fff;
        border-radius: 1rem;
        box-shadow: 1px 1px 50px var(--widget-publication-preview-shadow);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 100px;
        transform: translateY(-100vh);

        .pi-times {
            position: absolute;
            top: 2rem;
            right: 2rem;
            font-size: 1.5rem;
            color: var(--gray-500);
        }

        &>div:first-child {
            height: 200px !important;
        }

        .actions {
            position: absolute;
            left: 12px;
            right: 12px;
            bottom: 12px;
            display: flex;
            flex-direction: column;
            gap: .75rem;

            .p-button {
                background: var(--global-highlight-color);
                border-color: var(--global-highlight-color);

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.cancel {
                    background: var(--gray-100);
                    color: var(--gray-500);
                    border: none;
                }
            }
        }

        .title {
            font-family: 'Rubik', sans-serif;
            font-weight: 500;
            font-size: 1.2rem;
            padding: 0 2rem;
            text-align: center;
            margin-bottom: 1.2rem;
            color: var(--gray-900);
        }

        ul {
            margin-bottom: 2rem;
        }

        .desc {
            padding: 0 1.5rem;
            text-align: center;
            font-family: 'Rubik', sans-serif;
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1.4;

            span {
                font-weight: 500;
                color: var(--global-highlight-color);
                border-bottom: 1px dashed;

              img {
                margin-left: 2px;
                margin-right: 2px;
              }
            }
        }
    }
}
