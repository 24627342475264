.rankings-table {

    .row {
        display: flex;
        font-size: 13px;
        height: 30px;
        align-items: center;
        color: var(--bluegray-900);
        position: relative;

        &:not(.head) {
            transition: all .4s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .6;
            }
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 5px;
            height: 1px;
            background: linear-gradient(to left, #e7f0ff, #fff);
            content: "";
        }

        &.head {

            &::after {
                display: none;
            }
        }

        .player {
            width: 180px;
            color: initial;
            text-decoration: none;
        }

        .values {
            display: flex;
            width: calc(100% - 180px);
            height: 100%;
            align-items: center;

            &>div {
                width: calc(100% / 7);
                font-size: 12px;
                font-weight: 300;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    transition: all .4s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                    }
                }

                &.sort {

                    span {
                        background: var(--team-players-sort-bgd);
                        border-radius: 6px;
                        color: var(--team-players-sort-color);
                        padding: 3px 4px;
                    }
                }
            }
        }
    }
}
