.legs-nodes {
    margin-top: 2rem;
    margin-bottom: 40px;

    .nodes-row {
        display: flex;
        gap: .8rem;
        max-width: 100%;
        overflow-x: auto;
        padding: 2rem 1rem 4rem;

        &.one {
          justify-content: center;
        }

    }

    .playoff-node {
        display: flex;
        flex-direction: column;

        .shootout {
            position: absolute;
            right: 10px;
            top: calc(50% - .7rem);
            background: #fff;
            padding: .2rem .5rem;
            border-radius: .6rem;
            border: 1px solid var(--blue-50);
            font-size: .8rem;

        }

        .row {
            display: flex;
            height: 44px;
            align-items: center;
            padding: 0 16px;

            &:first-child {

                .name {
                    border-bottom: 1px solid var(--blue-50);
                }
            }

            .emb {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                img {
                    height: 30px;
                }
            }

            .name {
                width: 160px;
                height: 44px;
                line-height: 44px;
                text-align: left;
                box-sizing: border-box;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
            }

            .score {
                width: 18px;
                text-align: right;
                font-weight: 500;
            }
        }
    }

    .p-organizationchart-node-content {
        border: none !important;
        border-radius: 12px;
        box-shadow: 1px 1px 25px rgb(59 130 246 / 11%);
        background: #fff;
        padding: 0 !important;
        transition: all .35s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: .6;
            box-shadow: 1px 1px 15px rgb(59 130 246 / 20%);
        }

        .p-node-toggler {
            display: none;
        }
    }
}
