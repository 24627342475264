.match-pubs {
    min-height: 57vh;

    .non-ideal-state {
        height: 400px;
    }

    .feed-card {
        border-radius: 12px;
        background: #fff;
        box-shadow: 2px -1px 20px var(--blue-50);
        padding: 8px;
        margin-bottom: 8px;
        position: relative;

        .preview {
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            display: flex;
            align-items: center;
            border-radius: 8px;

            img {
                width: 100%;
            }
        }

        .title {
            position: absolute;
            height: auto;
            left: 8px;
            right: 8px;
            bottom: 8px;
            background: linear-gradient(to top, var(--blue-800), transparent);
            border-radius: 8px;
            padding: 14px 20px;
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            color: var(--blue-50);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: 13px;
                font-weight: 300;
                margin-top: 1px;
            }
        }

        .category {
            position: absolute;
            top: 16px;
            right: 16px;
            background: #3b82f630;
            padding: 2px 5px;
            border-radius: 4px;
            color: var(--blue-100);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            font-size: 13px;
        }
    }
}

.match-loader {
    min-height: calc(100vh - 320px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.match-events_grid {
    border: 1px solid #e4edfd;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 40px;

    .p-timeline-event {
        align-items: center;
        // border-bottom: 1px solid #e4edfd;
        min-height: 60px;

        &:first-child {
            height: 70px;
            box-sizing: border-box;
            padding-top: 10px;

            .p-timeline-event-separator {
                height: 70px;

                .p-timeline-event-connector {
                    top: -5px;

                    &::after {
                        top: 27px;
                    }
                }
            }
        }

        &:last-child {

            .p-timeline-event-separator {

                &::before {
                    content: '';
                    width: 1px;
                    background-color: #e4edfd;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        .p-timeline-event-separator {
            height: 60px;
            align-items: center;
            justify-content: center;
            position: relative;

            .p-timeline-event-connector {
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 0;
                width: 1px;
                background-color: #e4edfd;

                &::after {
                    content: '';
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #fff;
                    position: absolute;
                    top: 17px;
                    left: -12px;
                    border: 1px solid #e4edfd;
                }
            }

            .event-icon {
                position: relative;
                z-index: 1;
            }
        }

        .event-minute {
            font-weight: 500;
            color: var(--bluegray-600);

            span {
                position: relative;

                &>* {
                    position: relative;
                    z-index: 2;
                    font-weight: 500;
                }
            }
        }

        .events-item_body_primary {
            display: block;
            width: 100%;
            color: initial;
            text-decoration: none;

            span {
                font-size: 15px;
                font-weight: 400;
                padding: 0px 13px;
                background-color: var(--blue-50);
                box-shadow: 2px 2px 50px var(--blue-100);
                color: var(--bluegray-900);
                border-radius: 11px;
                letter-spacing: .4px;
                transition: all .4s linear;

                &:hover {
                    cursor: pointer;
                    box-shadow: 2px 2px 70px var(--blue-100);
                    opacity: .78;
                }
            }
        }

        .events-item_body_secondary {
            font-size: 11px;
            font-weight: 300;
            letter-spacing: .5px;
            color: var(--bluegray-400);
            padding: 7px 13px 0;
            display: inline-block;
            text-decoration: none;
        }
    }
}

.match-main_score {
    width: 152px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .state-indicator {
        position: absolute;
        top: -24px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            width: .7rem;
            height: .7rem;
            display: block;
            background: var(--green-400);
            border-radius: 50%;
            margin-right: .35rem;
        }

        span {
            font-size: .8rem;
            color: var(--bluegray-400);
        }
    }

    .match-main_score_cell {
        width: 70px;
        height: 80px;
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 48px;
        font-weight: 500;
        overflow: hidden;
        margin-top: -28px;
        background: var(--match-main-score);

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            background: rgba(0, 0, 0, .06);
        }

        &::after {
            content: '';
            background: rgba(255, 255, 255, .07);
            width: 120px;
            height: 120px;
            border-radius: 50%;
            position: absolute;
            bottom: -20px;
            left: -80px;
            z-index: 2;
        }
    }
}

.match {

    &.desktop {
        position: relative;
        display: flex;
        min-height: calc(100vh - 320px);
        align-items: flex-start;

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            //align-items: center;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .primary {
                width: 70%;
                display: flex;
                flex-direction: column;

                .match-main {
                    width: 100%;
                    padding-top: 50px;

                    &>div:first-child {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-bottom: 16px;

                        .match-main_score {
                            margin: 0 18px;
                            position: relative;

                            .shootout {
                                position: absolute;
                                bottom: -22px;
                                font-size: .9rem;
                                text-align: center;
                                left: 0;
                                right: 0;
                                color: var(--bluegray-800);
                            }
                        }

                        .match-main_teams {
                            width: calc((100% - 188px)/2);
                        }

                        .match-main_team {
                            display: flex;
                            align-items: center;
                            color: initial;
                            text-decoration: none;

                            .match-team_name {
                                font-size: 16px;
                                text-transform: uppercase;
                                font-weight: 500;
                                color: var(--bluegray-700);
                                transition: all .4s ease-in-out;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .78;
                                }
                            }

                            &.__home {
                                flex-direction: row-reverse;

                                .emblem {
                                    margin-left: 10px;
                                }
                            }

                            &.__away {

                                .emblem {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .match-main_score_cell {
                        margin-top: 0;
                    }

                    .match-btn_set {
                        text-align: center;
                        margin-top: 30px;
                        margin-bottom: 30px;

                        .p-button {
                            transition: all 0.35s ease-in-out;

                            &.active {
                                background: var(--match-btn-set-bgd) !important;
                                color: var(--match-btn-set-color) !important;
                            }
                        }
                    }
                }

                .match-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .secondary {
                width: 30%;
                box-sizing: border-box;
                padding-left: 26px;
                padding-top: 164px;
            }
        }

        .match-info_grid {
            height: 400px;
            width: 100%;
            border: 1px solid #e4edfd;
            margin-bottom: 40px;
            display: grid;
            grid-template-columns: 1fr 2fr;
            border-radius: 8px;

            .match-general_info {
                padding: 20px;
                border-right: 1px solid #e4edfd;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;

                .match-staff_item {
                    border-top: 1px solid #e4edfd;
                    display: flex;
                    min-height: 28px;
                    align-items: center;
                    font-size: .9rem;
                    color: var(--bluegray-500);

                    &:first-child {
                        border-top: none;
                    }

                    &>div:first-child {
                        box-sizing: border-box;
                        width: 64px;
                    }
                }
            }

            .mean {
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 6px;
                color: var(--bluegray-800);

                &.interactive {

                    span {
                        border-bottom: 1px dashed var(--bluegray-700);
                        transition: all .4s ease-in-out;

                        &:hover {
                            cursor: pointer;
                            opacity: .68;
                        }
                    }
                }
            }

            .meta {
                color: var(--bluegray-500);
                margin-bottom: 18px;
            }

            .matches-balance {
                padding: 20px;

                .mean {
                    margin-bottom: 30px;
                }
            }

            &>div:last-child {
                display: grid;
                grid-template-rows: 2fr 1fr;

                &>div:first-child {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                &>div:last-child {
                    border-top: 1px solid #e4edfd;
                }
            }
        }

        .match-events_grid {
            border: 1px solid #e4edfd;
            border-radius: 8px;
            width: 100%;
            margin-bottom: 40px;

            .p-timeline-event {
                align-items: center;
                // border-bottom: 1px solid #e4edfd;
                min-height: 60px;

                &:first-child {
                    height: 70px;
                    box-sizing: border-box;
                    padding-top: 10px;

                    .p-timeline-event-separator {
                        height: 70px;

                        .p-timeline-event-connector {
                            top: -5px;

                            &::after {
                                top: 27px;
                            }
                        }
                    }
                }

                &:last-child {

                    .p-timeline-event-separator {

                        &::before {
                            content: '';
                            width: 1px;
                            background-color: #e4edfd;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }

                .p-timeline-event-separator {
                    height: 60px;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .p-timeline-event-connector {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        z-index: 0;
                        width: 1px;
                        background-color: #e4edfd;

                        &::after {
                            content: '';
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            background: #fff;
                            position: absolute;
                            top: 17px;
                            left: -12px;
                            border: 1px solid #e4edfd;
                        }
                    }

                    .event-icon {
                        position: relative;
                        z-index: 1;
                    }
                }

                .event-minute {
                    font-weight: 500;
                    color: var(--bluegray-600);

                    span {
                        position: relative;

                        &>* {
                            position: relative;
                            z-index: 2;
                            font-weight: 500;
                        }
                    }
                }

                .events-item_body_primary {

                    span {
                        font-size: 15px;
                        font-weight: 400;
                        padding: 0px 13px;
                        background-color: var(--match-events-item-bgd);
                        box-shadow: 2px 2px 50px var(--match-events-item-shadow);
                        color: var(--bluegray-900);
                        border-radius: 11px;
                        letter-spacing: .4px;
                        transition: all .4s linear;

                        &:hover {
                            cursor: pointer;
                            box-shadow: 2px 2px 70px var(--match-events-item-shadow);
                            opacity: .78;
                        }
                    }
                }

                .events-item_body_secondary {
                    font-size: 11px;
                    font-weight: 300;
                    letter-spacing: .5px;
                    color: var(--bluegray-400);
                    padding: 7px 13px 0;
                    display: inline-block;

                    transition: all .4s linear;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                    }
                }
            }
        }

        .rosters-content {
            border: 1px solid #e4edfd;
            border-radius: 8px;
            width: 100%;
            margin-bottom: 40px;
            display: flex;

            .roster-cell {
                width: 50%;

                &.__home {
                    border-right: 1px solid #e4edfd;

                    .roster-list_item {
                        position: relative;
                        color: initial;
                        text-decoration: none;

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            right: 0px;
                            left: -64px;
                            height: 1px;
                            background: linear-gradient(to left, transparent, #e4edfd);
                        }

                        &:last-child {

                            &::before {
                                content: none;
                            }
                        }
                    }
                }

                .lineup {

                    &::before {
                        background: var(--statsblock-lineup-line);
                    }
                }

                .roster-list {
                    position: relative;
                    margin: 10px auto 20px;

                    .roster-list_item {
                        display: flex;
                        height: 35px;
                        align-items: center;
                        font-size: 14px;
                        color: initial;
                        text-decoration: none;

                        span {
                            width: 34px;
                            font-weight: 500;
                            color: var(--bluegray-600);
                        }

                        div {
                            color: var(--bluegray-900);
                        }

                        .p-tag {
                            position: absolute;
                            background: var(--orange-500);
                            font-size: .9rem;
                            width: 3.5rem;
                            right: 20px;

                            span {
                                color: var(--orange-50);
                            }
                        }
                    }
                }

                &.__away {

                    .roster-list_item {
                        flex-direction: row-reverse;
                        position: relative;
                        transition: all .4s linear;

                        &:hover {
                            cursor: pointer;
                            opacity: .6;
                        }

                        span {
                            text-align: right;
                        }

                        .p-tag {
                            left: 20px;
                            right: unset;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0px;
                            right: -64px;
                            height: 1px;
                            background: linear-gradient(to right, transparent, #e4edfd);
                        }

                        &:last-child {

                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.mobile {
        padding: 16px 12px;

        .match-main {
            box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.2);
            border-radius: 15px;
            position: relative;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .match-main_teams {
                position: absolute;
                left: 16px;
                right: 16px;
                top: -20px;
                display: flex;
                justify-content: space-between;

                .match-main_team {
                    width: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .match-team_name {
                        font-size: 10px;
                        color: var(--bluegray-800);
                        font-weight: 600;
                        margin-top: 12px;
                        line-height: 12px;
                        text-align: center;
                    }
                }
            }

            .content-tabs.mobile {
                padding: 0;
                margin-top: 36px;
                margin-bottom: -2px;

                .content-tabs_wrap {
                    background: none !important;
                }
            }
        }

        .match-content {
            padding: 16px 0;

            .match-general_info {
                padding: 10px 0;
                text-align: center;
                color: var(--bluegray-600);
                font-weight: 300;
                line-height: 14px;
                font-size: 11px;
                letter-spacing: 0.02em;
            }

            .matches-balance {
                margin-top: 20px;
                margin-bottom: 8px;
            }

            .p-timeline {
                margin-top: 20px;
            }

            .p-timeline-event {
                min-height: 56px !important;
            }

            .event-minute {
                font-size: 12px;
                color: var(--bluegray-800);
                font-weight: 500;
            }

            .events-item_body {

                &>div {
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.02em;
                    color: var(--bluegray-800);
                }

                &>span {
                    font-size: 11px;
                    font-weight: 300;
                    letter-spacing: 0.02em;
                    color: var(--bluegray-400);
                    white-space: nowrap;
                }
            }

            .rosters-content {
                display: flex;

                .roster-list {
                    width: 50%;

                    &.__away {

                        .roster-list_item {
                            flex-direction: row-reverse;

                            &>div {
                                text-align: right;
                            }
                        }
                    }

                    .roster-list_item {
                        display: flex;
                        height: 32px;
                        align-items: center;

                        &:nth-child(even) {
                            background: rgba(0, 0, 0, .02);
                        }

                        div {
                            width: calc(100% - 22px);
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 12px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        span {
                            width: 22px;
                            height: 22px;
                            border-radius: 5px;
                            background: var(--blue-100);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--blue-400);
                            font-size: 11px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
