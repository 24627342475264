.match-card.v2 {
    width: 220px;

    .match-card_body {
        background: #fff !important;
        border-radius: 8px;
        border: 2px solid var(--blue-400);
        justify-content: center;
        height: 110px;
        width: 220px;

        &.upcoming {
            border-color: var(--bluegray-50);

            .match-card_body_emblem {
                .p-tag {
                    background: var(--bluegray-50);
                    color: var(--bluegray-500);
                }
            }
        }

        &.won {
            border-color: transparent;
            box-shadow: 2px 2px 20px #f1fff6;
            .match-card_body_emblem {
                .p-tag {
                    // background: var(--green-50);
                    // color: var(--green-500);
                    background: var(--bluegray-50);
                    color: var(--bluegray-500);
                }
            }
        }

        &.draw {
            border-color: transparent;
            box-shadow: 2px 2px 20px #fffaea;
            .match-card_body_emblem {
                .p-tag {
                    // background: var(--yellow-50);
                    // color: var(--yellow-400);
                    background: var(--bluegray-50);
                    color: var(--bluegray-500);
                }
            }
        }

        &.lost {
            border-color: transparent;
            box-shadow: 2px 2px 20px #fff1f1;
            .match-card_body_emblem {
                .p-tag {
                    // background: var(--red-50);
                    // color: var(--red-400);
                    background: var(--bluegray-50);
                    color: var(--bluegray-500);
                }
            }
        }

        .match-card_body_score {
            height: 38px;
            margin-top: 22px;

            span {
                font-size: 38px;
            }
        }

        .match-card_body_datetime {
            padding-top: 4px;
        }

        .match-card_body_emblem {
            top: 20px;

            .p-tag {
                top: -32px;
                background: var(--blue-400);
                color: #fff;
                font-weight: 300;
                font-size: 11px;
            }

            &.__home {

                .p-tag {
                    left: 0;
                }
            }

            &.__away {

                .p-tag {
                    right: 0;
                }
            }
        }
    }
}
