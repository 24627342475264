.mediablock.team-scoped {

    &.desktop {
        border-radius: 16px;
        height: unset !important;
        background: none !important;

        .stripes {
            left: 20%;

            div:first-child {
                background-color: var(--blue-50) !important;
                opacity: .3;
            }

            div:last-child {
                background-color: var(--blue-50) !important;
            }
        }

        .container {
            flex-direction: column-reverse;
            padding: 24px 12px 50px 0;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;

            &>div {
                width: 100%;

                &.content-wrap {
                    padding: 0;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: auto;

                    .categories {
                        width: 100%;
                        margin-bottom: 14px;

                        .category-row {
                            justify-content: flex-start;

                            .category {
                                width: 16%;
                                margin-right: 14px;
                                font-size: 14px;
                                border-radius: 6px;
                                padding-top: 3px;
                            }
                        }
                    }

                    .item-intro {
                        width: 40%;
                        box-sizing: border-box;
                        padding-right: 24px;
                        margin: 10px 0;

                        .item-content_date {
                            font-size: 13px;
                            line-height: 17px;
                            margin-bottom: 6px;
                        }

                        .item-content_title {
                            font-size: 20px;
                            line-height: 23px;
                        }
                    }

                    .previews {
                        width: 60%;
                        margin: 10px 0;

                        .preview-item {
                            width: 108px;
                            height: 63px;
                            margin-right: 12px;
                        }
                    }
                }

                &.item-wrap {
                    height: 464px;
                    margin-top: 26px;

                    .item {
                        height: 100%;
                    }
                }
            }
        }
    }
}
