.app-page {
    background: #fff;
    position: relative;

    &>div {
        position: relative;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc(((100% - 1280px)/2) + 1280px*.7 + 10px);
        background: linear-gradient(to left, #fcfcff, #fff);
        border-right: 1px solid #f3f3fb;
    }
}


.filled-page {
    // background: linear-gradient(to bottom, #fcfcff, #fff);
    background: #fcfcff;
    position: relative;
    min-height: 600px;

    &::before {
        content: '';
        top: 0;
        height: 240px;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 0;
        background: var(--global-filled-page);
    }

    .container {
        display: flex;

        .col {
            width: 300px;
            padding-right: 20px;
            box-sizing: border-box;
        }

        .content {
            width: calc(100% - 300px);
        }
    }
}
