.mb2 {
    position: relative;
    background: linear-gradient(to bottom, #fff, var(--gray-100));
    z-index: 2;
    margin: 0 8px;
    border-radius: 12px;
    //aspect-ratio: 1 / 1.27;
    margin-bottom: -20px;
    box-sizing: border-box;
    padding: 2rem .5rem 8.5rem;

    .p-skeleton {
        background: #fff;

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(133, 174, 215, 0.1), rgba(255, 255, 255, 0));
        }

        &.slide-skeleton {
            aspect-ratio: 16 / 9;
            height: unset !important;
            border-radius: 12px;
        }

        &.date-skeleton {
            margin-top: 24px;
        }

        &.title-skeleton {
            margin-top: 12px;
        }

        &.title-skeleton-bottom {
            margin-top: 6px;
        }
    }

    .splide__pagination {
        //top: calc(((100vw - 32px)*.75) + 10px);
        justify-content: flex-start;
        position: relative;
        margin-top: 12px;
        padding-left: 10px;
        bottom: unset;

        .splide__pagination__page {
            width: 14px;
            border-radius: 5px;
            background: var(--mobile-mediablock-indicator);
            opacity: 1;
            height: 6px;
        }

        .splide__pagination__page.is-active {
            width: 20px;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;
            background: var(--mobile-mediablock-indicator-active);
            opacity: 1;
            height: 6px;
        }
    }

    .nav-circle {
        position: absolute;
        background: #fff;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        box-shadow: 1px 1px 40px var(--mobile-mediablock-navcircle);
        bottom: 20px;
        transition: all 0.3s linear;
    }

    .nav-spot {
        width: 20px;
        height: 8px;
        background: var(--mobile-mediablock-navspot);
        border-radius: 50%;
        bottom: 3px;
        transition: all 0.3s linear;
        position: absolute;
        z-index: 5;
    }

    .nav {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 12px;
        height: 64px;
        background: #fff;
        display: flex;

        .p-skeleton {
            border-radius: 50%;
            margin-left: 16px;
            margin-top: 7px;

            &:first-child {
                margin-left: 12px;
                margin-top: -4px;
            }
        }

        .nav-cat {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70px;

            .nav-cat_icon {
                width: 33px;
                height: 33px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 6px;
                transition: all 0.25s linear;

                img {
                    height: 38px;
                    transition: all 0.4s ease-in-out;
                }
            }

            span {
                font-size: 9px;
                font-weight: 400;
                color: var(--bluegray-600);
                text-transform: uppercase;
                position: relative;
                z-index: 6;
            }

            &.active {

                .nav-cat_icon {
                    width: 46px;
                    height: 46px;
                    margin-top: -13px;

                    img {
                        height: 46px;
                    }
                }
            }
        }
    }
}
