.rankings {
  overflow: hidden;

    .rankings-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 42px;

        &:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        .rankings-player {
            display: flex;
            font-size: 13px;
            align-items: center;
            padding-left: 12px;
            color: initial;
            text-decoration: none;

            .rankings-player_pos {
                width: 22px;
            }

            .emblem {
                margin-right: 7px;
            }

            .rankings-player_info {
                letter-spacing: .03em;

                div:nth-child(2) {
                    font-size: 10px;
                    font-weight: 500;
                    margin-top: 1px;
                }
            }
        }

        .rankings-values {
            display: flex;
            font-size: 13px;

            .rankings-values_item {
                width: 44px;
                text-align: center;

                &:last-child {
                    font-weight: 700;
                }

                span {
                  font-weight: 400;
                  font-size: 12px;
                }
            }
        }
    }

    &.desktop {

        .container {

            &.full-mode {
                align-items: flex-start;
                flex-direction: row;

                .leader {
                    width: 40%;
                    height: 100%;
                    position: relative;

                    .leader-name {
                        background: #fff;
                        clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
                        position: absolute;
                        top: 20px;
                        height: 64px;
                        display: flex;
                        padding-left: 20px;
                        align-items: center;
                        z-index: 0;

                        span {
                            font-size: 64px;
                            font-weight: bold;
                            color: var(--blue-100);
                            position: absolute;
                            left: 5px;
                            top: -10px;
                        }

                        div {
                            position: relative;
                            z-index: 2;
                            padding-left: 24px;
                            padding-right: 40px;
                            text-transform: uppercase;

                            &>div:last-child {
                                font-weight: 300;
                                font-size: 14px;
                                text-transform: none;
                            }
                        }
                    }

                    .leader-values {
                        position: absolute;
                        z-index: 3;
                        right: 39px;
                        bottom: -11px;
                        background-color: #fff;
                        height: 64px;
                        min-width: 200px;
                        clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0% 100%);
                        padding-left: 80px;
                        padding-right: 20px;
                        display: flex;
                        align-items: center;
                        font-weight: 300;

                        i {
                            font-style: normal;
                            font-size: 28px;
                            font-weight: 700;
                            margin-right: 6px;

                            .penalty {
                              margin-right: 0;
                              font-size: 18px;
                            }
                        }

                        &>div:last-child {
                            margin-left: 14px;
                            padding-left: 14px;
                            border-left: 1px solid #eee;
                        }
                    }

                    .leader-avatar {
                        height: 550px;
                        box-sizing: border-box;
                        padding-top: 40px;
                        padding-right: 40px;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        z-index: 2;
                        transition: all .35s ease-in-out;

                        &:hover {
                            cursor: pointer;
                            opacity: .8;
                        }

                        img {
                            height: 100%;
                            margin-top: 10px;
                        }
                    }
                }

                .rest {
                    width: 60%;

                    .rankings-row {
                        width: calc(100% - 15px);

                        .rankings-player {
                            transition: all .35s ease-in-out;

                            &:hover {
                                cursor: pointer;
                                opacity: .7;
                            }
                        }
                    }
                }
            }
        }
    }
}
