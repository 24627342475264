.mixed-item {

    .splide__pagination {
        // top: calc(((100vw - 32px)*.75) + 10px);
        // position: relative;
        //bottom: -30px;
        justify-content: flex-start;
        padding-left: 10px;

        .splide__pagination__page {
            width: 14px;
            border-radius: 5px;
            background: var(--blue-100);
            opacity: 1;
            height: 6px;
        }

        .splide__pagination__page.is-active {
            width: 20px;
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;
            background: var(--blue-400);
            opacity: 1;
            height: 6px;
        }
    }

    .splitted-append {
        padding-top: 10px;
        padding-left: 12px;
        padding-right: 20px;
        font-size: 14px;
    }

    .image-append {
        width: 100%;
        border-radius: 8px;
    }

    .notice {
        text-align: left;
        padding-left: 12px;
    }

    .mixed-item_content {
        a {
            font-weight: 500;
            text-decoration: none;
            color: var(--blue-500);
            transition: all .35s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .78;
            }
        }
    }
}
