.team-lineup {
    padding-top: 24px;
    border-top: 1px solid #f3f3fb;

    .title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: var(--team-events-title);
        font-weight: 500;

        span:not(.p-tag) {
            text-transform: uppercase;
        }

        .p-tag {
            font-size: 11px;
            background: var(--global-tag-background);
            color: var(--global-tag-color);
            margin-left: 9px;
        }
    }

    .team-lineup_body {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0 28px;

        .p-skeleton {
            background: #4085f6;
        }

        .empty {
            color: var(--blue-200);
        }
    }
}
