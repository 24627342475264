.top-lineup {
    .content-card {
        padding-bottom: 30px;
    }

    .p-dropdown {
        background: transparent;
        margin: 22px 16px 12px;
        width: calc(100% - 32px);
        border-color: var(--widget-lineup-line);

        &:not(.p-disabled).p-focus, &:not(.p-disabled):hover {
            border-color: var(--widget-lineup-line);
        }

        .p-inputtext {
            color: var(--widget-lineup-line);
            font-size: 14px;
        }

        .pi-chevron-down {
            color: var(--widget-lineup-line);
            font-size: 12px;
        }
    }
}
