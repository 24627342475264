.content-card {
    position: relative;
    margin-top: 14px;
    border-radius: 8px;
    padding: 14px;

    .title {
        text-transform: uppercase;
        position: absolute;
        text-transform: uppercase;
        background: #fff;
        border-radius: 20px;
        font-weight: 500;
        padding: 6px 16px 4px;
        top: -12px;
        left: -12px;
        font-size: 14px;
    }

    .content-empty {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;

        .pi {
            font-size: 26px;
            margin-bottom: 10px;
            color: var(--blue-400);
        }

        div {
            color: var(--blue-100);
        }
    }
}
