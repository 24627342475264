.breadcrumbs {
    display: flex;
    align-items: center;

    &.mobile {
        height: 34px;
        box-sizing: border-box;
        padding: 0 18px;
        font-size: 11px;
        color: var(--bluegray-300);

        i.pi {
            font-size: 10px;
            margin: 0 5px;
        }
    }
}
