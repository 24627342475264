.container {
    position: relative;
    margin: 0 auto;
    width: 1280px;

    @media screen and (min-width: 1600px) {
        width: 1440px;
    }

    .cols {
        display: flex;

        .col-primary {
            width: 70%;
            padding-right: 20px;
        }

        .col-secondary {
            width: 30%;
            box-sizing: border-box;
            padding-left: 20px;
        }

        &.stiched {
            .col-secondary {
                &>div {
                    position: fixed;
                    top: 20px;
                }
            }
        }

        .col-equal {
            width: 50%;
            position: relative;

            &.left-filled {
                &::before {
                    content: '';
                    position: absolute;
                    left: -300px;
                    top: 1px;
                    bottom: 0;
                    right: 100%;
                    background: inherit;
                }

                &>div:not(.perspective) {
                    position: relative;
                    z-index: 1;
                }

                .perspective {
                    position: absolute;
                    top: 0;
                    left: -300px;
                    right: 0;
                    bottom: 20px;
                    transform: rotate(-8deg);
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: 70%;
                    opacity: .05;
                    z-index: 0;
                }
            }
        }
    }
}
