.publication {
    position: relative;
    padding: 24px 20px;

    .title {
        margin-right: 82px;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 20px;
        min-height: 64px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: linear-gradient(to right, var(--blue-100), transparent);
        }
    }

    .tags {
        padding: 10px 0 14px;

        .p-chip {
            margin-right: 5px;
            margin-bottom: 6px;
            font-size: 11px;
            font-weight: 300;
            padding: 0 11px;
            background: var(--blue-50);
            color: var(--blue-500);

            .p-chip-icon {
                font-size: 11px;
                margin-right: 3px;
            }

            .p-chip-text {
                margin: 4px 0 3px;
            }
        }
    }

    .meta {
        display: flex;
        position: relative;
        height: 40px;
        box-sizing: border-box;
        padding-top: 6px;
        align-items: center;
        justify-content: space-between;

        &>div {
            font-weight: 300;
            font-size: 12px;
            color: var(--bluegray-500);
        }

        .p-tag {
            position: absolute;
            top: -13px;
            right: 0px;
        }
    }
}
