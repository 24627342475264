.layout-desktop {

    .desktop-section {
        display: flex;
        justify-content: center;
        position: relative;
        // max-height: 800px;
        border-top: 1px solid var(--surface-border);

        .section-stripe {
            left: 25%;
            width: 50%;
            top: 0;
            bottom: 0;
            position: absolute;
            clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%);
        }

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .primary {
                width: 70%;

                .sub-cols {
                    display: flex;
                    flex-wrap: wrap;

                    .banner-node {
                        padding: 0 20px;
                    }

                    .main {
                        width: 55%;
                    }

                    .secondary {
                        width: 45%;
                        box-sizing: border-box;
                        padding: 20px;
                    }
                }
            }

            .secondary {
                width: 30%;
                height: 100%;
            }
        }
    }
}
