.club-matches {
    // margin-top: 10px;
    background: #fff;

    .title {
        text-transform: uppercase;
        font-weight: 500;
    }

    .matches-carousel.desktop {
        background: transparent !important;
        margin-top: -10px;

        .container {
            width: 100%;
            padding-top: 0;
        }
    }

    .matches-carousel.desktop .container::after {
        background: linear-gradient(to right, transparent, #fff);
        top: 18px;
    }

    .matches-carousel.desktop .container::before {
        background: linear-gradient(to left, transparent, #fff);
        top: 18px;
    }

    .matches-carousel.desktop .container .matches-carousel_cards {
        margin-left: -50px;
    }
}
