.mb-item {

    .title {
        color: var(--bluegray-700);
        padding-left: 14px;
        padding-right: 14px;
        margin-top: 16px;

        .date {
            font-weight: 300;
            font-size: 13px;
            color: var(--bluegray-500);
        }

        .text {
            margin-top: 6px;
            font-size: 14px;
            max-height: 51px;
            overflow: hidden;
        }
    }

    .mb-item_media {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
        aspect-ratio: 16/9 ;
        // background-size: 150%;
        background-size: 100%;
        background-position: center;
        position: relative;

        .play_btn {
            position: absolute;
            z-index: 99;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            backdrop-filter: blur(4px);
            background: rgba(255, 255, 255, .24);
        }

        &.empty {
            aspect-ratio: 16/9;
        }

        img {
            height: 100%;
            position: relative;
        }
    }
}
