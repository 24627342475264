.btn-link, .p-button.btn-link {
    background: none;
    font-size: 11px;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    border: none;
    font-family: 'Inter', sans-serif;

    .p-ink {
        display: none !important;
    }

    &.mobile-menu-trigger {

        @media screen and (max-width: 401px) {
            max-width: 140px;
        }
    }

    .p-button-label {
        font-weight: 400;
        max-width: 144px;
        box-sizing: border-box;
        padding-left: 4px;
        padding-right: 4px;
        text-align: left;
    }

    &::before {
        width: 14px;
        height: 14px;
    }

    &:enabled:hover {
        background: none;
        cursor: pointer;
    }

    &:enabled:focus, &:active, &:enabled:active {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
    }
}
