.lineup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;

    .manager {
        position: absolute;
        font-size: .75rem;
        bottom: -20px;
        color: var(--blue-800);
    }

    &::before {
        background: var(--widget-lineup-line);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-mask-image: url('./football.svg');
        -webkit-mask-position: center;
        -webkit-mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
    }

    .lineup-row {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 25%;
        box-sizing: border-box;
        padding-top: 10px;
        align-items: center;
        position: relative;
        z-index: 1;

        .lineup-row-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 60px;
            transition: all .35s ease-in-out;
            color: initial;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                opacity: .75;
            }

            .lineup-row-item_num {
                width: 34px;
                height: 34px;
                background: var(--statsblock-lineup-num) !important;
                border-radius: 12px;
                box-shadow: 1px 1px 20px var(--widget-lineup-num-shadow);
                font-size: 15px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--widget-lineup-num-color);
                position: relative;

                .qty {
                    position: absolute;
                    width: 1rem;
                    height: 1rem;
                    background: #fff;
                    border-radius: .35rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .7rem;
                    color: var(--widget-card-title-color);
                    box-sizing: border-box;
                    //padding-top: .1rem;
                    top: -.4rem;
                    left: -.4rem;
                }

                .p-tag.mvp {
                    position: absolute;
                    font-size: .5rem;
                    background-color: var(--orange-500);
                    color: var(--bluegray-900);
                    top: -5px;
                    right: -14px;
                    z-index: 1;
                }

                img {
                    height: 40px;
                    transform: rotateY(90deg);
                    transition: all .25s ease-in-out;
                    position: relative;
                    z-index: 2;

                    &.loaded {
                        transform: rotateY(0deg);
                    }

                    &.holder {
                        opacity: .17;
                    }
                }
            }

            .lineup-row-item_name {
                font-size: 10px;
                font-weight: 500;
                letter-spacing: 0.5px;
                margin-top: 4px;
                text-transform: uppercase;
                color: var(--bluegray-900);
            }

            .lineup-row-item_team {
                font-size: 8px;
                white-space: nowrap;
                margin-top: -1px;
                color: var(--statsblock-lineup-team) !important;
            }
        }
    }
}
