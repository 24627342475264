.mobile-rating {

    h3 {
        margin: 0 1rem 1rem 1.75rem;
    }

    .publication-body_hint {
        margin: 0 1rem 1rem;
        background: var(--blue-50);
        border: 1px solid var(--blue-100);
        border-radius: .75rem;
        padding: .9rem;
        font-size: .9rem;
    }

    .publication-body {
        margin: 0 1rem;

        .rankings {

            .rankings-row {
                width: calc(100% - 2rem);
            }
        }
    }
}
