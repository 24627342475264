.avatar-shield {
    position: relative;
    width: 260px;
    height: 300px;

    &.clickable {
        transition: all .35s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: .8;
        }
    }

    .avatar {
        position: absolute;
        z-index: 1;
        -webkit-mask-image: url('./tall_shape.svg');
        -webkit-mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
        width: 100%;
        top: -40px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 110%;
            margin-top: 20px;
        }
    }

    &::before {
        position: absolute;
        content: '';
        left: 3px;
        bottom: 0;
        top: 0;
        right: 3px;
        background: #fff;
        -webkit-mask-image: url('./shape.svg');
        -webkit-mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
    }
}
