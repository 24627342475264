.app-link {
    font-size: 15px;
    border-bottom: 1px dashed;
    transition: all 0.35s ease-in-out;
    padding-bottom: 3px;
    width: auto;
    position: relative;
    white-space: nowrap;
    display: inline-block;

    &:hover {
        cursor: pointer;
        border-color: transparent !important;
        opacity: .58;
    }
}

._nested-node {
    padding: 7px 16px;
    cursor: pointer;
    transition: all 0.35s ease-in-out;

    @media screen and (max-width: 1450px){
      padding: 7px 6px;
    }

    @media screen and (max-width: 1120px){
      padding: 7px 2px;
    }

    &.active {
      opacity: .68;
      background: var(--blue-300);
    }

    &:hover {
        opacity: .68;
        background: var(--blue-500);
    }
}



  @media only screen and (min--moz-device-pixel-ratio: 1.1), (-o-min-device-pixel-ratio: 10/9), (-webkit-min-device-pixel-ratio: 1.1), (min-device-pixel-ratio: 1.1){
    .p-tooltip.p-tooltip-bottom.first {
      margin-left: 3%;
      left: 0!important;
    }
    .p-tooltip-bottom.first .p-tooltip-arrow {
      left: 10% !important;
    }
  }

  @media screen and (max-width: 1380px) and (min-width: 1181px){
    .p-tooltip.p-tooltip-bottom.first {
      margin-left: 3%;
      left: 0!important;
    }
    .p-tooltip-bottom.first .p-tooltip-arrow {
      left: 10% !important;
    }
  }

  @media screen and (max-width: 1100px){
    .p-tooltip.p-tooltip-bottom.first {
      margin-left: 3%;
      left: 0!important;
    }
    .p-tooltip-bottom.first .p-tooltip-arrow {
      left: 10% !important;
    }
  }





