.feed-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 2px 2px 35px #f5f9ff70;
    margin-bottom: 20px;
    overflow: hidden;

    &:first-child {
        background: #fcfcff
    }

    .title {
        min-height: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: var(--bluegray-700);
        font-size: 14px;
        font-weight: 500;
        position: relative;
        padding: .75rem 60px .75rem 20px;

      .title-date {
            position: absolute;
            right: 20px;
            font-weight: 300;
        }

        .cat-tag {
            margin-right: .5rem;

            .p-tag {
                background: var(--blue-50);
                color: var(--blue-500);
                border: 1px solid var(--blue-100);
            }
        }

        &::after {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            content: '';
            background: linear-gradient(to right, transparent, var(--blue-100));
            bottom: 0;
        }
    }

    .card-content {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        position: relative;

        &::after {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            content: '';
            background: linear-gradient(to left, transparent, var(--blue-100));
            bottom: 0;
        }

        .selection-item {
            width: 100%;
            display: flex;
            padding: 0 20px;
            box-sizing: border-box;

            .info {
                width: 50%;
                box-sizing: border-box;
                padding-top: 1.5rem;

                .p-tag {
                    background: var(--global-tag-color);
                    border: 1px solid var(--global-tag-border-color);
                    color: var(--global-tag-background);
                }

                .title {
                    padding: 0;
                }

                .secondary-title {
                    text-transform: uppercase;
                    font-weight: 300;
                    color: var(--bluegray-500);
                    margin-top: 1rem;
                }
            }

            .selection-body {
                width: 50%;
                box-sizing: border-box;
                padding: 1.5rem 0;

                .lineup {
                    &::before {
                        background: var(--statsblock-lineup-line);
                    }
                }

                .lineup .lineup-row .lineup-row-item .lineup-row-item_num {
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: .6rem;
                }

                .lineup .lineup-row .lineup-row-item .lineup-row-item_name {
                    font-size: .55rem;
                    font-weight: normal;
                    color: var(--top-search-accent) !important;
                    text-align: center;
                }

                .lineup .lineup-row .lineup-row-item.highlight {

                    .lineup-row-item_name {
                        font-weight: 500;
                    }

                    .lineup-row-item_num {
                        background: var(--blue-600);

                        .pi {
                            font-size: .75rem;
                            color: var(--global-highlight-color);
                        }
                    }
                }
            }
        }

        .pub-preview {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            padding: 0 20px;

            .media {
                width: 50%;

                &:hover {
                    cursor: pointer;
                }

                &>div {
                    margin: 1rem 0;
                    border-radius: .75rem;
                    overflow: hidden;
                    position: relative;
                    background: none !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .play_btn {
                        width: 32px;
                        height: 32px;
                        position: absolute;
                        z-index: 3;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            .tags {
                width: 50%;
                box-sizing: border-box;
                padding-left: 1rem;
                padding-top: 1rem;

                .cat-tag {
                    margin-bottom: .25rem;

                    .p-tag {
                        background: var(--blue-500);
                        color: var(--blue-50);
                        border: 1px solid var(--blue-600);
                    }
                }

                .p-tag {
                    margin-right: .25rem;
                    //margin-bottom: .25rem;
                    background: var(--blue-50);
                    border: 1px solid var(--blue-100);
                    color: var(--blue-400);
                    transition: all .4s ease-in-out;

                    &:hover {
                        opacity: .78;
                        cursor: pointer;
                    }
                }
            }
        }

        .body {
            width: 60%;

            .match {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 200px;
                border-right: 1px solid #e4edfd;
                color: var(--bluegray-800);
                box-sizing: border-box;
                padding: 16px;
                justify-content: space-between;
                transition: all .4s linear;

                &:hover {
                    cursor: pointer;
                    opacity: .7;
                }

                .meta {
                    display: flex;
                    justify-content: space-between;

                    &>div:first-child {
                        width: 100%;
                        padding-right: 1rem;
                        text-align: right;
                        box-sizing: border-box;
                    }

                    .score {
                        width: 40%;
                        display: flex;
                        justify-content: center;
                        height: 100%;
                        align-items: center;
                        color: var(--bluegray-700);

                        &>div {
                            background: var(--bluegray-50);
                            border-radius: 14px;
                            padding: 3px 13px;
                            align-items: center;
                            display: flex;
                        }

                        &.won {
                            &>div {
                                background: var(--green-50);
                            }
                        }

                        &.lost {
                            &>div {
                                background: var(--red-50);
                            }
                        }

                        .alterfont {
                            font-size: 20px;
                            font-weight: 500;
                        }

                        .pi {
                            margin: 0 3px;
                        }
                    }
                }

                .date {
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 300;
                }

                .time {
                    font-weight: 600;
                    font-size: 18px;
                }

                .location {
                    font-size: 13px;
                    font-weight: 300;
                    color: var(--bluegray-500);
                }

                .opposer {
                    width: 100%;

                    .muted {
                        font-size: 13px;
                        font-weight: 300;
                        color: var(--bluegray-500);
                    }

                    .opposer-body {
                        margin-top: 8px;
                        display: flex;
                        width: 100%;
                        align-items: center;

                        &.team {
                            margin-bottom: 4px;
                            margin-top: 0;
                            opacity: .8;
                        }

                        .emblem {
                            margin-right: 8px;
                        }

                        .name {
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .col {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .matches-balance {
                padding: 12px;

                .mean {
                    font-size: 11px;
                    text-align: center;
                    font-weight: 300;
                    margin-bottom: 4px;
                    color: var(--bluegray-900);
                }

                .matches-balance_values {
                    font-size: 9px;
                }
            }

            .meta-block {
                width: 100%;
                box-sizing: border-box;
                padding-left: 10px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: relative;

                .meta-block_badge {
                    width: 70px;
                    height: 70px;
                    border: 1px solid #e4edfd;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    position: absolute;
                    top: -30px;
                    left: -35px;

                    img {
                        width: 44px;
                    }

                    .label {
                        position: absolute;
                        font-size: 9px;
                        font-weight: 500;
                        text-transform: uppercase;
                        background: #fff;
                        bottom: -17px;
                    }
                }

                .meta-block_item {
                    display: flex;
                    font-size: 13px;
                    color: var(--bluegray-600);
                    align-items: center;
                    margin-top: 10px;

                    .am-icon {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
