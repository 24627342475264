.tournament {

    &.desktop {

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .stages-nav {
                padding: 14px 16px 20px;
                text-align: center;

                .p-tag {
                    transition: all .4s ease-in-out;
                    color: var(--global-tag-background);
                    background: var(--global-tag-value);
                    margin-right: 9px;
                    padding: 5px 15px;
                    transition: all .4s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                    }

                    &.selected {
                        color: var(--global-tag-value);
                        background: var(--global-tag-background);
                    }
                }
            }

            .content-btns {
                display: flex;
                height: 4.1rem;
                width: 100%;
                box-sizing: border-box;
                justify-content: center;
                background: var(--tournament-nav-bgd);
                //background: var(--teal-600);
                //background: #272b34;
                //background: linear-gradient(to bottom, var(--bluegray-800), var(--bluegray-700));
                border-bottom: 1px solid var(--tournament-nav-border);
                position: relative;
                margin-bottom: 2rem;
                align-items: stretch;

                &::before, &::after {
                    content: '';
                    background: inherit;
                    border-bottom: inherit;
                    top: 0;
                    bottom: -1px;
                    width: 50%;
                    left: -50%;
                    position: absolute;
                }

                &::after {
                    left: unset;
                    right: -50%;
                }

                .content-btn {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    font-size: 1rem;
                    padding: .2rem 1.6rem 0 1.5rem;
                    text-transform: uppercase;
                    transition: max-width .4s ease-in-out;
                    color: var(--tournament-content-btn);

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }

                    .pi {
                        font-size: 1.2rem;
                        margin-right: .8rem;
                    }
                }

                .subcontent {
                    transition: max-width .4s ease-in-out;
                    max-width: 0;
                    overflow: hidden;
                    border-right: 1px solid var(--tournament-nav-border);

                    &.active {
                        max-width: unset;
                        border-left: 1px solid var(--tournament-nav-border);
                    }

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            .content {
                width: 100%;
                margin-top: -10px;
                padding-bottom: 40px;
                min-height: 460px;
                position: relative;

                .content-loader {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 9;
                    transition: all .6s ease-in-out;
                    visibility: visible;
                    opacity: 1;

                    &.loaded {
                        visibility: hidden;
                        opacity: 0;
                    }
                }

                .node-wrap {
                    transition: all .8s ease-in-out;
                    visibility: hidden;
                    opacity: 0;

                    &.loaded {
                        overflow-x: auto;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.mobile {
        background: #fff;

        .stages-nav {
            padding: 14px 16px 0;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 6px;

            .p-tag {
                transition: all .4s ease-in-out;
                color: var(--blue-500);
                background: var(--blue-50);
                padding: 5px 15px;

                &.selected {
                    color: var(--blue-50);
                    background: var(--blue-500);
                }
            }
        }

        .content {
            padding: 10px 20px 20px;

            .content-body {
                min-height: 300px;
                box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.1);
                border-radius: 15px;
                position: relative;

                .content-loader {
                    display: flex;
                    min-height: 300px;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 24px;
                        color: var(--blue-600);
                    }
                }
            }
        }
    }
}
