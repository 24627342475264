.achievements-carousel {
    margin-top: 20px;

    .content-card {
        height: 200px;

        .splide__pagination {
            bottom: -15px;
            justify-content: flex-start;
            padding-left: 5px;

            .splide__pagination__page {
                width: 10px;
                border-radius: 5px;
                background: var(--matches-carousel-color);
                opacity: 1;
                height: 7px;
            }

            .splide__pagination__page.is-active {
                width: 17px;
                border-radius: 5px;
                margin-left: 5px;
                margin-right: 5px;
                background: var(--matches-carousel-deep);
                opacity: 1;
            }
        }

        .achievement-card {
            height: 130px;
            border-radius: 8px;
            margin: 16px 0px 14px;
            position: relative;
            background: rgba(255, 255, 255, 0.09);
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 17px;

            .info {
                padding-right: 125px;

                .name {
                    color: var(--achievement-name-color);
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: .4px;
                    transition: all .35s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .72;
                    }
                }

                .team {
                    font-size: 13px;
                    font-weight: 300;
                    color: var(--blue-200);
                }

                .description {
                    margin-top: 16px;
                    font-size: 13px;
                    color: var(--achievement-description-color);
                }

                .p-tag {
                    position: absolute;
                    bottom: -4px;
                    font-size: 11px;
                    line-height: 11px;
                    padding: 2px 7px;
                    background: var(--achievement-date-bgd);
                }
            }

            .avatar-shield {
                transform: scale(.42);
                position: absolute;
                right: 10px;
                transform-origin: 100% 50%;
                margin-top: -8px;
            }

            .achievement {
                position: absolute;
                right: 70px;
                bottom: -10px;
            }
        }
    }
}
