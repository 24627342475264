.p-overlaypanel.desktop-search_op {

    &:before {
        border-bottom-color: var(--search-panel-corner);
    }

    .p-overlaypanel-content {
        background: var(--search-panel-gradient);
        border-radius: 6px;
    }
}

.search-suggestions {
    margin-top: 10px;
    padding: 0 16px;

    .top {

        .p-tag {
            background: rgba(255, 255, 255, 0.09);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);

            .pi {
                color: var(--yellow-500);
            }
        }
    }

    .items {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;

        .item {
            border-radius: 6px;
            position: relative;
            background: rgba(255, 255, 255, 0.09);
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            box-sizing: border-box;
            padding: 44px 8px 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            transition: all 0.35s ease-in-out;

            &:hover {
                cursor: pointer;
                -webkit-backdrop-filter: blur(2.5px);
                background: rgba(255, 255, 255, 0.2);
            }

            .emblem {
                position: absolute;
                top: -10px;
            }

            .name {
                text-align: center;
                text-transform: uppercase;
                font-size: 12px;
                color: var(--search-panel-text);
                height: 28px;
                overflow: hidden;
            }

            .p-tag {
                background: var(--global-filled-page);
                position: absolute;
                bottom: -9px;
                padding: 2px 5px;
                font-size: 11px;

                .pi {
                    font-size: 11px;
                }
            }
        }
    }
}

.search {
    position: absolute;
    top: 15px;
    right: 65px;
    left: 0;
    box-sizing: border-box;
    padding-left: 16px;

    &._desktop {
        position: relative;
        top: unset;

        &._rightAligned {
            margin-right: 14px;
            width: 320px;
        }

        .p-input-icon-left {
            width: 100%;
        }

        input {
            width: 100%;
            background: var(--global-tag-background);
            border-color: var(--global-tag-border-color);
            color: var(--global-tag-value);

            &::placeholder {
                color: var(--global-tag-color);
            }

            &:focus {
                outline: none !important;
                box-shadow: none !important;
                border-color: var(--global-tag-border-color) !important;
            }
        }

        .p-inputtext:enabled:hover {
            border-color: var(--global-tag-border-color);
        }
    }

    .p-inputtext:enabled:hover {
        border-color: var(--global-tag-border-color);
        box-shadow: none;
        outline: none;
        color: var(--global-tag-value);
    }

    .pi {
        color: var(--global-tag-color) !important;
    }

    input {
        width: 100%;
        background: var(--mobile-search-input);
        border-color: var(--mobile-search-input);

        &::placeholder {
            color: var(--mobile-search-placeholder);
        }
    }
}
