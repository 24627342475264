.matches-list {

    &.desktop {

        .container {
            display: flex;
            flex-direction: row;

            .primary {
                width: 75%;
            }

            .secondary {
                width: 25%;
            }

            .matches-list_group {
                width: 100%;
                margin-top: 12px;
                margin-bottom: 40px;
                border: 1px solid var(--blue-50);
                border-radius: 12px;

                &:first-child {
                    margin-top: 30px;
                }

                &:nth-child(even) {
                    background: rgba(0, 0, 0, .01);
                }

                .matches-list_group_title {
                    text-align: center;
                    margin-bottom: 18px;
                    margin-top: -9px;

                    span {
                        background: var(--blue-50);
                        border: 1px solid var(--blue-50);
                        padding: 6px 19px;
                        border-radius: 16px;
                        box-shadow: 2px 2px 15px var(--blue-50);
                    }
                }

                .matches-list_group_list_item {
                    display: flex;
                    height: 38px;
                    width: 100%;
                    border-bottom: 1px solid var(--blue-50);
                    transition: all .4s ease-in-out;
                    padding: 0 18px;
                    text-decoration: none;
                    color: initial;

                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                        background: var(--blue-50);
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    .time, .location {
                        width: 160px;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        color: var(--bluegray-600);
                    }

                    .location {
                        justify-content: flex-end;
                        font-size: 13px;
                        color: var(--bluegray-500);
                    }

                    .match {
                        width: calc(100% - 320px);
                        display: flex;

                        .score {
                            width: 90px;
                            display: flex;
                            align-items: center;

                            &>* {
                                width: 30px;
                                text-align: center;
                            }

                            span {
                                font-size: 13px;
                                color: var(--bluegray-600);
                            }

                            div {
                                font-weight: 600;
                                color: var(--bluegray-700);
                            }
                        }

                        .team {
                            width: calc((100% - 90px)/2);
                            align-items: center;
                            display: flex;

                            &:first-child {
                                padding-left: 12px;

                                .emblem {
                                    margin-right: 12px;
                                }
                            }

                            &:last-child {
                                justify-content: flex-end;
                                padding-right: 12px;
                                text-align: right;

                                .emblem {
                                    margin-left: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
