.balance-skeleton {
    height: 131px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
}

.matches-balance {

    .matches-balance_title {
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 600;
        letter-spacing: 0.02em;
        color: var(--bluegray-700);
        margin-bottom: 8px;
    }

    .matches-balance_body {
        height: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0px 4px 20px rgba(59, 130, 246, 0.2);
    }

    .matches-balance_values {
        box-sizing: border-box;
        padding: 8px 4px;
        display: flex;
        font-size: 11px;
        line-height: 14px;
        font-weight: 300;
        letter-spacing: 0.02em;
        color: var(--bluegray-600);
    }
}
