.stats-card {
    margin-bottom: 30px;

    &.goals-rate {
        .stats-card_inner_top__value {
            font-size: 70px !important;
        }
    }

    .stats-card_inner {
        border-radius: 15px;
        border-top-left-radius: 0;
        height: 100%;

        .stats-card_inner_top {
            position: relative;

            &._holder {
                height: 100%;
                min-height: 320px;
            }

            .avatar-shield {
                transform: scale(0.65);
                transform-origin: 0 0;
                position: absolute;
                bottom: -100px;
                right: -100px;
            }

            .stats-card_inner_top__title {
                font-weight: 600;
                font-size: 15px;
                max-width: 200px;
                align-items: center;
                display: inline-flex;
                text-transform: uppercase;
                box-sizing: border-box;
                padding: 0 10px 4px 16px;
                border-radius: 15px;
                position: absolute;
                top: -12px;
                border-bottom-left-radius: 0;
                z-index: 2;
            }

            .stats-card_inner_top__value {
                font-size: 96px;
                font-weight: 700;
                line-height: 100px;
                padding: 40px 18px 0px 18px;
                display: block;
                position: relative;
            }

            .stats-card_inner_top__avatar {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 50%;
                height: 130%;

                &>div:not(.avatar-shield) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-size: cover;
                    background-position: top center;
                    background-repeat: no-repeat;

                    &._empty {
                        opacity: 0.15;
                        background-position-x: 10px;
                    }
                }

                &._team {

                    &>div {
                        background-size: 120px;
                        background-position: bottom 15px right 10px;
                    }
                }
            }

            .stats-card_inner_top__player {
                padding-left: 18px;
                padding-bottom: 24px;
                position: relative;
                z-index: 1;

                &>div:first-child {
                    font-weight: 500;
                    transition: all .35s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                    }
                }

                &>div:nth-child(2) {
                    font-weight: 700;
                    font-size: 12px;
                    opacity: .68;
                    letter-spacing: 0.03em;
                }
            }

            .stats-card_inner_top__emblem {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 130px;
                width: 130px;
                overflow: hidden;
                z-index: 0;
                opacity: .25;

                div {
                    width: 160px;
                    height: 160px;
                    margin-left: -40px;
                    margin-bottom: -60px;
                    background-size: cover;
                    background-position: top right;
                    transform: perspective(75em) rotateY(20deg) rotateX(4deg);
                }
            }
        }

        .stats-card_inner_list {

            .stats-card_inner_list_row {
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: space-between;
                height: 44px;
                padding: 0px 18px;
                transition: .35s ease-in-out;

                &:hover {
                    cursor: pointer;
                    opacity: .6;
                }

                &:nth-child(odd) {
                    background: rgba(0, 0, 0, .05);
                }

                .stats-card_inner_list_player {
                    font-size: 14px;

                    &>div:nth-child(2) {
                        font-size: 12px;
                        opacity: .68;
                        font-weight: 700;
                        letter-spacing: 0.03em;
                    }
                }

                .stats-card_inner_list_value {
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }

        .stats-card_inner_footer {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            background: rgba(255, 255, 255, .08);
            transition: all .35s ease-in-out;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            font-size: 14px;
            position: relative;
            z-index: 99;

            span {
                opacity: .78;
                transition: all .35s ease-in-out;
            }

            &:hover {
                cursor: pointer;
                background: rgba(255, 255, 255, .18);

                span {
                    opacity: 1;
                }
            }
        }
    }

    &.mobile {

    }
}
