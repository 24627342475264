.feed {
    padding: 40px;

    .p-skeleton {
        margin-bottom: 12px;
        border-radius: 12px;
        background: #fff;

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(246, 249, 252, 0.8), rgba(255, 255, 255, 0));
        }
    }
}
