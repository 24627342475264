.icons-navi {
    display: flex;
    padding-left: 26px;
    padding-top: 6px;

    &.desktop {
        padding:  0;
        height: 100%;

        .icons-navi_item {
            width: 5rem;
            height: 100%;
            margin-right: 0;
            border-radius: 0;

            span {
                font-size: .8rem;
                color: var(--tournament-content-btn);
                font-weight: normal;
            }

            .icons-navi_item_icon {
                width: 1.6rem;
                height: 1.6rem;
                mask-size: 1.6rem;
                mask-position: center;
                -webkit-mask-size: 1.6rem;
                -webkit-mask-position: center;
                transition: all 0.35s ease-in-out;
                background-color: var(--tournament-nav-muted) !important;
            }

            &.active {
                background: var(--tournament-nav-active-bgd);
                border-right: 1px solid var(--tournament-nav-border);
                border-left: 1px solid var(--tournament-nav-border);

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border-right: none;
                }

                span {
                    color: var(--tournament-nav-active);
                }

                .icons-navi_item_icon {
                    background-color: var(--tournament-content-btn) !important;
                }
            }
        }
    }

    .icons-navi_item {
        width: 44px;
        height: 44px;
        border-radius: 8px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: opacity 0.35s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: .78;
        }

        span {
            font-weight: 500;
            font-size: 8px;
            line-height: 9px;
            text-align: center;
            display: block;
            margin-top: 4px;
            transition: all 0.35s ease-in-out;
        }

        .icons-navi_item_icon {
            width: 18px;
            height: 18px;
            mask-size: 18px;
            mask-position: center;
            -webkit-mask-size: 18px;
            -webkit-mask-position: center;
            transition: all 0.35s ease-in-out;
        }
    }
}
