.club-top {
    display: flex;
    border-bottom: 1px solid #e4edfd;
    flex-wrap: wrap;

    .club-top_intro {
        width: 70%;
        // border-right: 1px solid #e4edfd;
        display: flex;
        position: relative;
        padding-top: 30px;
        padding-bottom: 18px;

        .club-top_primary {
            padding: 1rem;
            padding-left: 30px;

            .club-top_name {
                // margin-bottom: 12px;
                font-size: 1.5rem;
                text-transform: uppercase;
            }

            .club-top_location {
                margin-top: .2rem;
                margin-bottom: .5rem;
                font-size: .9rem;
                opacity: .7;
            }

            .p-tag.sub {
                font-size: 11px;
                padding: 3px 7px 1px;
                line-height: 11px;
                font-weight: normal;
                transition: all .35s ease-in-out;

                &:hover {
                    cursor: pointer;
                    opacity: 0.78;
                }

                .pi {
                    font-size: 11px;
                }

                &.negative {
                    background: var(--gray-50);
                    color: var(--gray-500);
                }

                &.positive {
                    background: var(--global-tag-background);
                    color: var(--global-highlight-color);
                }
            }
        }

        .club-top_tournament {
            position: absolute;
            bottom: 8px;
            right: 8px;
        }
    }

    .club-top_meta {
        width: 30%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 18px;
        padding-top: 30px;
        padding-right: 0px;

        .club-top_subscriptions {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            position: relative;

            .club-top_subscriptions_title {
                margin-top: 6px;
                font-weight: 300;
                font-size: 10px;
                text-align: center;
                color: var(--bluegray-700);
                padding-bottom: 3px;
            }

            .club-top_subscriptions_value {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: absolute;
                bottom: -14px;
                left: 0;
                right: 0;
                z-index: 2;
                font-size: 14px;
                font-weight: 500;
                color: var(--bluegray-800);

                &>div:first-child {
                    margin-bottom: 4px;
                }
            }

            .club-top_subscriptions_icon {
                margin-left: 12px;
                height: 34px;
                position: relative;
                margin-left: 0;
                text-align: center;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 20px;
                    background: linear-gradient(to top, #fff, transparent);
                }

                .pi {
                    font-size: 34px;
                    color: var(--yellow-300);
                }
            }
        }
    }

    .club-top_teams {
        width: 100%;
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0px;
            height: 1px;
            background: linear-gradient(to left, #e7f0ff, transparent);
            content: "";
        }

        .title {
            font-size: 13px;
            color: var(--bluegray-800);
            margin-bottom: 4px;
        }

        .list {

            .p-tag {
                margin-right: 6px;
                transition: all .4s ease-in-out;
                background: var(--global-tag-background);
                color: var(--global-highlight-color);

                &:hover {
                    cursor: pointer;
                    opacity: .78;
                }

                &.selected {
                    background: var(--global-highlight-color);
                    color: var(--global-tag-background);
                }
            }
        }
    }
}
