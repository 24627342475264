.stats-block {
    padding: 20px 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;

    &.desktop {
        min-height: 380px;
        align-items: start;
        padding: 20px 200px;

        &:not(.blured) {
            display: grid;
            grid-template-columns: 1fr 2.5fr;
            grid-gap: 40px;
        }

        .stats-side {
            width: 100%;
            box-sizing: border-box;
            padding-top: 94px;

            .top-lineup {
                border-radius: 20px;

                &.no-dropdown {

                    .content-card {
                        padding-top: 30px;
                    }
                }

                .p-dropdown {
                    margin-top: 1.1rem;
                    border-color: var(--statsblock-lineup-dropdown);

                    .p-inputtext, .pi-chevron-down {
                        font-size: .9rem;
                        color: var(--statsblock-lineup-dropdown);
                    }
                }

                .content-card {
                    //padding-top: 30px;

                    .lineup {
                        height: unset !important;
                        aspect-ratio: 1/.9 !important;
                        padding-top: 15px;

                        &::before {
                            background: var(--statsblock-lineup-line);
                        }

                        .lineup-row .lineup-row-item .lineup-row-item_num img {
                            height: 40px;
                            width: unset;
                            transform: rotateY(90deg);
                            transition: all .25s ease-in-out;

                            &.loaded {
                                transform: rotateY(0deg);
                            }

                            &.holder {
                                opacity: .17;
                            }
                        }
                    }
                }
            }

            .achievements-carousel {
                width: 100%;
                margin-top: 40px;
                margin-bottom: 40px;

                .content-card {
                    box-shadow: 2px 2px 25px rgb(171 201 251 / 25%) !important;
                }

                .splide {
                    width: calc((100vw - 440px)/3.5);
                }
            }
        }

        .stats-block_cards {
            position: relative;
            margin: 0 auto;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            padding-top: 80px;
            flex-wrap: wrap;

            // @media screen and (min-width: 1600px) {
            //     width: 1440px;
            // }

            .p-skeleton {
                width: 30% !important;
                background-color: transparent;
            }
        }

        &.blured {

            .stats-block_cards {
                filter: blur(7px);
            }

            .overlay {
                justify-content: center;

                .overlay-text {
                    text-transform: uppercase;
                }
            }
        }
    }

    &.mobile {
        padding-top: 50px;
        min-height: 450px;

        .stats-block_cards {

            .splide__track {
                padding-top: 50px;

                .splide__slide {
                    display: flex;
                    justify-content: center;
                }
            }

            .splide__pagination {
                bottom: -30px;
            }
        }
    }

    &.blured {

        .splide__track {
            filter: blur(7px);
        }

        .splide__pagination {
            display: none;
        }

        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background: rgba(0, 0, 0, .20);
            overflow: hidden;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &::before {
                content: '';
                position: absolute;
                width: 90%;
                height: 200vh;
                transform: rotate(30deg);
                background: rgba(255, 255, 255, 0.07);
                top: -50vh;
                left: 35%;
            }

            .overlay-text {
                margin: 0 2em 40px;
                font-weight: 600;
                font-size: 19px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                position: relative;
                background: rgba(22, 22, 22, 0.2);
                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(3.9px);
                -webkit-backdrop-filter: blur(3.9px);
                padding: 16px;
                border-radius: 12px;
            }

            .btn-pull {
                margin-bottom: 20px;
                position: relative;

                .p-tag {
                    background: rgba(22, 22, 22, 0.2) !important;
                    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(3.9px);
                    -webkit-backdrop-filter: blur(3.9px);
                    border: none !important;
                    font-size: 1rem;

                    .pi {
                        color: var(--yellow-500);
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
