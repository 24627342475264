.player {

    .content {
        display: flex;

        .focus {
            width: calc(100% - 340px);
            display: flex;
            flex-direction: column;

            .carousel {
                height: 140px;
                padding: 0 40px;
                display: flex;
                align-items: flex-end;
            }
        }

        .side {
            width: 340px;
            box-sizing: border-box;
            padding-left: 30px;
        }
    }
}
