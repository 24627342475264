.feed {
    position: relative;
    overflow: hidden;

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: var(--global-tag-background);
        color: var(--global-tag-value);
        border-color: var(--global-tag-border-color);

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .btn-pull {
        position: relative;
    }

    .feed-btn {
        position: relative;
        padding: 14px 10px 11px;

        .p-button {
            width: 100%;
        }
    }

    &.desktop {
        box-sizing: border-box;
        padding: 10px 10px 20px 20px;

        .btn-pull {
            text-align: left;
            padding-left: 0;
        }

        .p-paginator {
            background: transparent;
            margin-top: 14px;
        }

        .feed_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            background: #fff;
            box-shadow: 1px 1px 25px rgba(59,130, 246, .05);
            border-radius: 10px;
            margin-top: 14px;

            .feed_list_item {
                position: relative;
                height: 90px;
                display: flex;
                align-items: center;
                width: 100%;
                transition: all .4s ease-in-out;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0px;
                    height: 1px;
                    background: linear-gradient(to left, #e7f0ff, transparent);
                    content: '';
                    transition: all .7s linear;
                }

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                    // background: rgba(0, 0, 0, .02);
                    background: var(--blue-50);

                    &::after {
                        right: 150px;
                        background: linear-gradient(to left, var(--blue-100), transparent);
                    }

                    .feed_list_item_content {

                        .feed_list_item_content_title {
                            color: var(--blue-900) !important;
                        }
                    }
                }

                .feed_list_item_media {
                    width: 90px;
                    height: 90px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .media-thumb {
                        height: 70px;
                        width: 70px;
                        background-size: cover !important;
                        background-position: center !important;
                        border-radius: .75rem;
                        box-shadow: 1px 1px 15px var(--achievement-widget-shadow);
                        transition: all .35s ease-in-out;
                        opacity: .85;
                    }

                    &:hover {
                        .media-thumb {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 70px;
                        border-radius: 5px;
                    }
                }

                .feed_list_item_content {
                    width: calc(100% - 90px);
                    box-sizing: border-box;
                    padding: 20px;
                    padding-left: 18px;

                    .feed_list_item_content_title {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 14px;
                        margin-bottom: 6px;
                        text-transform: uppercase;
                        letter-spacing: .01em;
                        color: var(--global-titles-color);
                    }

                    .feed_list_item_content_date {
                        font-size: 12px;
                    }

                    .feed_list_item_text {
                        max-height: 82px;
                        margin-top: 18px;
                        overflow: hidden;
                        font-size: 12px;
                        line-height: 17px;
                    }
                }
            }
        }
    }

    &.mobile {
        border-top: 1px solid;

        .feed_list_item {
            position: relative;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;

            &:nth-child(even) {
                background: #fff;
            }

            .feed_list_item_media {
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border-radius: 6px;
                margin-right: 22px;

                img {
                    width: 100%;
                }
            }

            .feed_list_item_content {
                width: calc(100% - 86px);

                .feed_list_item_content_title {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    padding-right: 16px;
                }

                .feed_list_item_content_date {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    margin-top: 7px;
                }
            }
        }
    }
}
