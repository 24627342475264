@media screen and (max-width: 1680px) {
    .stats-block.desktop {
        padding: 20px 100px;

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__title {
            font-size: 13px;
            font-weight: 500;
            max-width: 180px;
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__avatar._team > div {
            background-size: 140px;
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__value {
            font-size: 74px;
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__avatar {
            ._empty {
                opacity: 0.15;
                background-position-x: 10px;
            }
        }

        .stats-card.goals-rate {

            .stats-card_inner .stats-card_inner_top .stats-card_inner_top__value {
                font-size: 60px !important;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .stats-block.desktop {
        padding: 0 80px;

        .stats-card {
            width: 31% !important;
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__title {
            font-size: 12px;
            max-width: 150px;
            top: -11px;
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__value {
            font-size: 62px;
        }

        .stats-card.goals-rate {

            .stats-card_inner .stats-card_inner_top .stats-card_inner_top__value {
                font-size: 50px !important;
            }
        }

        .stats-side .achievements-carousel .splide {
            width: calc((100vw - 200px) / 3.5)
        }

        .stats-card .stats-card_inner .stats-card_inner_top .stats-card_inner_top__avatar._team > div {
            background-size: 120px;
        }

        .stats-card .stats-card_inner .stats-card_inner_list .stats-card_inner_list_row .stats-card_inner_list_player {
            font-size: 13px;
        }

        .stats-card .stats-card_inner .stats-card_inner_list .stats-card_inner_list_row .stats-card_inner_list_player > div:nth-child(2) {
            font-size: 11px;
        }
    }
}

@media screen and (max-width: 1366px) {
    .stats-block.desktop {
        padding: 0 40px;

        .stats-card.goals-rate {

            .stats-card_inner .stats-card_inner_top .stats-card_inner_top__value {
                font-size: 50px !important;
            }
        }
    }
}

@media screen and (max-width: 1180px) {

    .player {

        &.filled-page {

            &::before {
                top: -1px;
                height: 200px;
            }

            .container {

                .col {
                    width: 220px;

                    .profile {

                        .avatar-shield {
                            width: 220px !important;
                            height: 250px !important;
                        }
                    }
                }

                .content {
                    width: calc(100% - 220px);

                    .side {
                        width: 200px;
                        padding-left: 15px;

                        .position-map {
                            margin-top: 1em;
                        }

                        .player-summary {
                            width: 100%;
                            justify-content: flex-start;
                            padding-top: 20px;

                            .grid {
                                grid-template-columns: 1fr 1fr 1fr;

                                .grid-item {

                                    .label {
                                        font-size: .55em;
                                        white-space: nowrap;
                                        margin-bottom: 8px;
                                    }

                                    .value {
                                        font-size: 1.3em;
                                        margin-bottom: 1em;
                                    }
                                }
                            }

                            .title {
                                font-size: 1em;
                                white-space: nowrap;
                            }
                        }
                    }

                    .focus {
                        width: calc(100% - 200px);

                        .carousel {
                            height: 60px;
                        }

                        .feed {
                            padding: 20px;

                            .feed-card {
                                box-shadow: 2px 2px 20px rgb(245 249 255 / 44%);

                                .opposer-body {
                                    font-size: .9em;
                                }
                            }
                        }
                    }
                }
            }
        }

        .container {
            width: 1000px;
        }
    }

    .layout-desktop {
        .top {

            .container {
                width: 1000px;
                position: relative;
            }

            .accent .navi-group .app-link {
                font-size: .85em;
                padding: .25em .75em;
                margin-right: .8em;
            }

            .accent .navi-group:nth-child(2) .app-link {
                margin-left: .8em;
            }

            .surface .emblem {
                width: 90px;
                top: -40px;
                position: absolute;
                left: calc((100% - 90px)/2);

                img {
                    width: 90px;
                    height: 90px;
                }
            }
        }

        .matches-carousel.extended {

            .container {
                width: 1000px;
            }
        }

        .mediablock.desktop {

            .container {
                width: 1000px;

                .content-wrap .p-tabmenu {
                    margin-bottom: .5rem;

                    .p-tabmenu-nav {

                        .p-tabmenuitem {

                            .p-menuitem-link {
                                font-size: .9rem;
                                padding-left: 1rem;
                                padding-right: .8rem;
                            }
                        }
                    }
                }

                .content-wrap .content {
                    min-height: 270px;
                    padding: 1.4rem 1.4rem 1.2rem;
                    transition: all .3s linear;

                    .item-intro {
                        .item-content_date {
                            font-size: .9rem;
                        }

                        .item-content_title {
                            font-size: 1.3em;
                            line-height: 1.2;
                        }

                        .tags {
                            margin-bottom: 2rem;

                            .tag-item {
                                font-size: .7em;
                            }
                        }
                    }

                    .previews {

                        .items-nav {

                            .items-nav_item {

                                .title {
                                    font-size: .75rem;
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .tournament {

            .container {
                width: 1000px;

                .content-btns .content-btn {
                    font-size: .8em;
                    height: 3em;
                    width: 13em;

                    .pi {
                        font-size: 1.3em;
                    }
                }

                .icons-navi.desktop .icons-navi_item {
                    height: 2.5em;

                    .icons-navi_item_icon {
                        width: 1.3em;
                        height: 1.3em;
                        -webkit-mask-size: 1.3em;
                    }

                    span {
                        font-weight: 400;
                        font-size: 0.7em;
                    }
                }
            }
        }

        .stats-block.desktop .stats-block_cards {

            .stats-card {
                width: 48% !important;
            }
        }

        .matches-list.desktop .container .primary {
            width: 1000px;

            .matches-list_group {
                width: 1000px;
                margin-bottom: 24px;

                .matches-list_group_title {
                    padding-right: 72px;
                }

                .matches-list_group_list_item {

                    .time {
                        width: 60px;
                    }

                    .match {
                        width: calc(100% - 120px);

                        .team {
                            font-size: .9em;
                        }
                    }
                }
            }
        }

        .footer {

            .container {
                width: 1000px;
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .layout-desktop {

        .top {

            .surface {
                height: 58px;

                .emblem {
                    width: 76px;
                    left: calc((100% - 76px) / 2);
                    top: -50px;

                    img {
                        width: 76px;
                        height: 76px;
                    }
                }
            }

            .accent {
                height: 58px;

                .navi-group {
                    height: 58px;

                    .app-link {
                        font-size: .8em;
                        margin-right: .7em;
                    }
                }
            }

            .container {
                width: 96%;

                .account {
                    &>.p-button {
                        font-size: 12px;
                    }
                }

                .search._desktop {
                    right: unset;
                    left: 110px;

                    .pi {
                        font-size: 13px;
                        top: 20px;
                        left: 10px;
                    }

                    input {
                        width: 180px;
                        box-sizing: border-box;
                        padding-left: 28px;
                        font-size: .7em;
                    }
                }

                .banners-node {

                    &>div {
                        margin-right: 14px;
                    }

                    img {
                        height: 52px;
                    }
                }
            }
        }

        .mediablock {

            .container {
                width: 96%;
                padding-top: 36px;

                .content-wrap {
                    padding-left: 1.5em;

                    .p-tabmenu {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 12px;
                        display: flex;
                        justify-content: center;
                    }

                    .content {
                        min-height: 200px;
                        padding: 1em 1em 2em;

                        .item-intro {

                            .item-content_date {
                                font-size: .75em;
                            }

                            .item-content_title {
                                font-size: 1.1em;
                                line-height: 1.2;
                                max-height: 42px;
                                overflow: hidden;
                            }

                            .tags {
                                max-height: 52px;
                                overflow: hidden;
                                margin-bottom: 22px;

                                .tag-item {
                                    font-size: .7em;
                                }
                            }
                        }

                        .items-nav {
                            padding: 0 34px;

                            .items-nav_item {

                                .title {
                                    font-size: .75rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        .matches-carousel.extended {

            .container {
                width: 96%;

                .matches-carousel_list {
                    padding: 0;

                    .matches-carousel_list_item {

                        .matches-carousel_list_item_datetime {
                            width: 56px;
                            text-align: left;
                        }

                        .matches-carousel_list_item_info {
                            width: calc(100% - 56px);
                            padding-left: 0;
                        }
                    }
                }
            }
        }

        .desktop-section {

            .container {
                width: 96%;

                .primary {
                    width: 50%;

                    .sub-cols {

                        .main {
                            display: none;
                        }

                        .secondary {
                            width: 100%;
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }

                .secondary {
                    width: 50%;
                }
            }
        }

        .stats-block.blured {

            .stats-block_cards {

                .stats-card:nth-child(5), .stats-card:nth-child(6) {
                    display: none;
                }
            }
        }

        .stats-block.desktop:not(.blured) {
            display: flex;
            flex-direction: column;
            padding: 0 3%;
            grid-gap: unset;

            .stats-side {
                padding-top: 42px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .achievements-carousel {

                    .splide {
                        width: 100%;
                    }
                }

                &>div {
                    width: 48.5%;

                    .content-card {
                        box-shadow: 2px 2px 25px rgb(171 201 251 / 18%) !important;
                    }
                }
            }
        }

        .highlights.desktop {

            .highlights_title, .container, .previews {
                width: 96%;
            }

            .previews {

                .preview-item {
                    width: 48.5%;

                    .preview-item_media {
                        background-position: center;
                        aspect-ratio: 16/9;
                        height: unset !important;
                        width: 100%;
                    }
                }
            }

            .highlights_title {
                padding-top: 20px;
                font-size: 1.2em;
                margin-bottom: 1.5em;
            }
        }
    }

    .footer.desktop {

        .container {
            width: 96%;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .tournament.desktop {

        &>.container {
            width: 96%;

            .content-btns .content-btn {
                width: auto;
                padding-left: 1em;
                padding-right: 1em;
            }

            .icons-navi.desktop .icons-navi_item span {
                font-size: .5em;
            }

            .matches-list.desktop .container {
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;

                .primary {
                    width: 98%;

                    .matches-list_group {
                        width: 100%;
                    }
                }
            }
        }
    }
}
