.match-card {
    width: 268px;
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: center;

    .match-card_body {
        width: 240px;
        height: 120px;
        border-radius: 14px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;

        .match-card_body_emblem {
            position: absolute;
            top: 34px;

            &.__home {
                left: -14px;

                .p-tag {
                    left: 20px;
                    border-bottom-left-radius: 10px;
                }
            }

            &.__away {
                right: -14px;

                .p-tag {
                    right: 20px;
                    border-bottom-right-radius: 10px;
                }
            }

            .p-tag {
                position: absolute;
                z-index: 3;
                top: 56px;
                white-space: nowrap;
                background: var(--bluegray-50);
                color: var(--bluegray-500);
            }
        }

        .match-card_body_teams {
            margin-top: 8px;
            font-weight: 700;
            font-size: 12px;
            line-height: 21px;
            letter-spacing: 0.02em;
        }

        .match-card_body_score {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 1;
            }

            i {
                margin: 0 5px;
            }
        }

        .match-card_body_datetime {
            padding-bottom: 4px;
            padding-top: 10px;

            div {
                text-align: center;
                width: 100%;
                font-weight: 300;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.02em;
            }
        }

        .match-card_body_tag {
            position: absolute;
            right: 16px;
            top: -11px;
        }
    }

    &.mobile {

    }
}
