.team-standings {
    // border: 1px solid #e4edfd;
    // border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden;
    // border-top-right-radius: 0;
    // border-top-left-radius: 0;
    padding: 8px;

  .p-selectbutton.p-buttonset {
    height: 44px;
    display: flex;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    margin-top: 5px;
    .p-button {
      border-color: var(--surface-border);
      font-size: 12px;
      height: 32px;
      color: var(--bluegray-600);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.p-highlight {
        color: var(--team-standings-highlight-text);
        background: var(--team-standings-highlight) !important;
      }

      &.p-highlight:hover {
        border-color: var(--surface-border);
      }
    }
  }

    .p-dropdown {
        width: 100%;
        height: 44px;
        font-size: 14px;
        border-radius: 0;
        border: none;
        background: linear-gradient(to top, #f3f3fb, #fff);
        box-shadow: 2px 2px 15px var(--bluegray-50);
        border-radius: 8px;

        .p-dropdown-label.p-inputtext {
            height: 42px;
            color: var(--bluegray-600);
            padding-top: 0;
            padding-bottom: 1px;
            display: flex;
            align-items: center;
            font-size: 14px;
            padding-left: 22px;
        }

        .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
            font-size: 12px;
            color: var(--bluegray-600);
        }
    }

    .standings-holder {
        margin: 8px 0 24px;
    }

    .standings {
        border-radius: 12px;
        overflow: hidden;

        &.desktop {
            .standings-head {
                height: 48px;
                font-size: 13px;
                color: var(--bluegray-500);
            }

            .standings-row {
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 5px;
                    height: 1px;
                    background: linear-gradient(to left, #e7f0ff, #fff);
                    content: '';
                }

                &.highlight {
                    background: var(--team-standings-highlight) !important;
                    color: var(--team-standings-highlight-text);
                    border-radius: 8px;
                }
            }

            .standings-row .standings-row_team .__pos {
                width: 36px;
            }
        }
    }
}
