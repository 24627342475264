.bottom-stories {
    padding: 30px 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .bottom-stories_title {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        padding-left: 24px;
        text-align: left;
        width: 100%;
        margin-bottom: 20px;
    }

    .splide__track {
        padding-bottom: 30px;
    }

    .splide__pagination {
        bottom: 0px;

        .splide__pagination__page.is-active {
            background: var(--blue-500)
        }
    }

    .bottom-stories_item {
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        .bottom-stories_item_media {
            width: 100%;

            div {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .bottom-stories_item_title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            padding: 18px;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 18.17%, rgba(0, 0, 0, 0) 62.44%);
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
        }
    }

    &.mobile {
        padding-top: 0px;
        background: linear-gradient(to bottom, #272b34 20%, #fff 30%) !important;

        .bottom-stories_title {
            display: none;
        }

        .splide__track {
            padding-top: 20px;

            .splide__slide {
                transition: all 0.2s ease-in-out;
                transform: scale(.98);
                margin-top: 10px;
                border-radius: 15px;

                &.is-active {
                    transform: scale(1);
                    margin-top: 0;
                    box-shadow: 2px 2px 20px #abc9fb45;
                }
            }
        }

        .splide__pagination {
            // bottom: -23px;
            //justify-content: flex-start;
            // padding: 0;

            .splide__pagination__page {
                width: 10px;
                border-radius: 5px;
                background: var(--blue-200);
                opacity: 1;
                height: 7px;
            }

            .splide__pagination__page.is-active {
                width: 17px;
                border-radius: 5px;
                margin-left: 5px;
                margin-right: 5px;
                background: var(--blue-400);
                opacity: 1;
            }
        }
    }

    &.desktop {

        .bottom-stories_title {
            padding-left: 0;
            margin-bottom: 34px;
            font-size: 25px;
            font-weight: 700;
            letter-spacing: 0;
            position: relative;
            display: flex;
            width: 1280px;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }
        }
    }
}
