#galleryWrapper {

    .preview {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 0.35s ease-in-out;
        border-radius: 24px;

        .photos-qty {
            background: rgba(0, 0, 0, 0.35);
            padding: 4px 18px;
            color: #fff;
            display: inline-block;
            position: absolute;
            bottom: 12px;
            right: 15px;
            border-radius: 12px;
        }

        &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.18);
        }
    }
}
