.group-prefix {
    margin-bottom: -20px;
}

.standings {

    .standings-row {
        height: 48px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-size: 14px;
        letter-spacing: 0.03em;

        &:not(.standings-head) {
            transition: all .4s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: .66;
            }
        }

        .standings-row_chess-inject {
            display: flex;
            width: calc(100% - 620px);
            margin-left: 20px;
            height: 100%;
            align-items: center;

            .standings-row_chess-inject_cell {
                justify-content: center;
                height: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;
                border-right: 1px solid;


                a {
                  color: initial;
                  text-decoration: none;
                }

                &:first-child {
                    border-left: 1px solid;
                }

                &._self {
                    background-color: #fdf7f2;
                }

                &>div {
                    font-size: 11px;
                    white-space: nowrap;
                    font-weight: 300;
                    transition: all 0.35s ease-in-out;

                    &:nth-child(2),&:nth-child(3) {
                        padding-top: 1px;
                        margin-top: 1px;
                        border-top: 1px solid var(--blue-50);
                    }

                    &:hover {
                        font-weight: 600;
                        opacity: .9;
                        cursor: pointer;
                    }
                }
            }

            &.head {

                .standings-row_chess-inject_cell {
                    border: none !important;
                }
            }
        }

        .standings-row_team {
            display: flex;
            width: 260px;

            .__team {
                display: flex;
                align-items: center;
                color: initial;
                text-decoration: none;

                .emb {
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin-right: 10px;
                    opacity: 0.75;

                    img {
                        height: 26px;
                    }
                }
            }


            .__pos {
                width: 60px;
                box-sizing: border-box;
                padding-left: 15px;
                padding-right: 9px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .standings-trend {
                    display: block;
                    width: 14px;
                    height: 14px;
                    background-size: 14px;
                    background-position: center;

                    &.__up {
                        background-image: url('./trend-up.png');
                    }

                    &.__down {
                        background-image: url('./trend-down.png');
                    }
                }
            }
        }

        .standings-row_values {
            display: flex;
            width: calc(100% - 260px);
            justify-content: flex-end;

            .__val {
                width: 60px;
                text-align: center;

                @media screen and (min-width: 1200px) {
                    width: 100px;
                }

                &.__pts {
                    font-weight: 500;
                    padding-top: 1px;
                }
            }
        }
    }

    &.chess-within {

        .standings-row_values {
            width: 420px;

             .__val {

                 @media screen and (min-width: 1200px) {
                     width: 60px;
                 }
             }
        }
    }

    &.mobile {

        .standings-row {
            display: flex;
            height: 40px;

            .standings-row_values {

                .__val {
                    width: 54px;
                }
            }
        }
    }

    &.chess {
        position: relative;
        margin-top: 10px;

        .chess-teams-list {
            position: absolute;
            left: 0;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            .standings-row {
                position: relative;
                background: var(--blue-50);
                font-size: 11px;

                &.standings-head {
                    border-bottom: none !important;
                    border-top-left-radius: 15px;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 10px;
                bottom: 5px;
                left: 10px;
                right: 0;
                box-shadow: 4px 0px 8px 2px rgba(34, 60, 80, 0.1);
                z-index: 0;
            }
        }

        .standings-row {

            &:last-child {
                border-bottom: none !important;
            }

            .standings-row_team {
                width: 200px;

                .__pos {
                    width: 42px;
                    opacity: .58;
                }
            }

            .standings-row_values {
                width: calc(100% - 200px);
            }
        }

        &.mobile {

            .standings-row {

                .standings-row_team {
                    width: 164px;

                    .__pos {
                        width: 38px;
                        opacity: .58;
                    }
                }

                .standings-row_values {
                    width: calc(100% - 164px);
                }
            }

            .chess-body {
                padding-left: 164px;
            }
        }

        .chess-body {
            padding-left: 200px;
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;

            .standings-row, .standings-row_values {
                width: auto;

                .__val {
                    width: 44px;
                    font-size: 13px;

                    &.__diff {
                        width: 90px;

                        i {
                            font-style: normal;
                            opacity: .58;
                            margin-left: 4px;
                        }
                    }
                }

                &.standings-head {

                    .chess-cells_cell {
                        border-right: none;
                    }
                }
            }

            .chess-cells {
                display: flex;
                height: 100%;

                .chess-cells_cell {
                    width: 40px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 500;
                    border-right: 1px solid var(--surface-hover);

                    &>span {
                        &:nth-child(2) {
                          padding-top: 2px;
                          margin-top: 2px;
                          border-top: 1px solid var(--blue-50);
                        }
                    }

                    &.self {
                        background: var(--blue-100);

                        span {
                            color: var(--blue-100);
                        }
                    }
                }
            }
        }
    }
}

.content-body {

    .standings {
        border-radius: 15px;
    }
}
