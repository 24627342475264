.highlights {
    padding: 20px 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .highlights_title {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        padding-left: 24px;
        text-align: left;
        width: 100%;
        margin-bottom: 20px;
    }

    &.desktop {

        .highlights_container {
            display: none;
        }

        .highlights_title, .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 40px;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .p-skeleton {
                background-color: transparent;
            }
        }

        .highlights_title {
            padding-left: 0;
            margin-bottom: 34px;
            font-size: 25px;
            font-weight: 700;
        }

        .container {
            flex-wrap: wrap;
            padding-bottom: 40px;
        }

        .previews {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .preview-item {
                width: 30%;
                margin-bottom: 30px;

                .preview-item_media {
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 15px;
                    margin-bottom: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .35s ease-in-out;
                    opacity: .75;

                    .play_btn {
                        transition: all .35s linear;
                    }

                    &:hover {
                        cursor: pointer;
                        opacity: 1;

                        .play_btn {
                            transform: scale(1.2);
                            opacity: .8;
                        }
                    }
                }

                .preview-item_title {
                    padding: 0 14px;
                    font-size: 15px;
                    font-weight: 600;
                    height: 38px;
                }
            }
        }
    }

    &.mobile {
        padding-top: 50px;

        .empty-highlights {
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #2e333e;
            border-radius: 12px;
            width: 94%;
            margin: 0 auto;
            color: #69738a;

            .pi {
                font-size: 26px;
                margin-bottom: 10px;
            }
        }

        .mobile_skeleton {
            width: 100%;
            padding: 0 12px;

            .p-skeleton {
                background: #2e333e;

                &:after {
                    opacity: .12;
                }
            }
        }

        .highlights_container {
            width: calc(100% - 20px);
            box-sizing: border-box;
            margin: 0 10px;
            overflow: hidden;
            box-shadow: 0px 4px 30px 5px rgba(59, 130, 246, 0.06);
            position: relative;

            .media-play {
                position: absolute;
                display: inline-block;
                top: calc(50% - 26px);
                left: calc(50% - 26px);
                transition: all .4s linear;

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }

            .highlights_container_media {
                width: 100%;
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                overflow: hidden;
                position: relative;

                img {
                    width: 100%;
                    margin-bottom: 0;
                }

                .media-linear {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 50%;
                }
            }

            .highlights_container_bottom {
                width: 100%;
                margin-top: -3px;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                padding: 12px 25px 16px;

                .highlights_container_title {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.03em;
                    min-height: 32px;
                }
            }
        }

        .highlights_carousel {
            border-radius: 8px;
            margin: 16px 10px 0;
            padding: 8px;
            width: calc(100% - 20px);
            overflow: hidden;
            padding-bottom: 30px;

            .splide__track {
                padding-top: 3px;
            }

            .splide__pagination {
                bottom: -23px;
                justify-content: flex-start;
                padding: 0;

                .splide__pagination__page {
                    width: 10px;
                    border-radius: 5px;
                    background: var(--mobile-highlights-indicator);
                    opacity: 1;
                    height: 7px;
                }

                .splide__pagination__page.is-active {
                    width: 17px;
                    border-radius: 5px;
                    margin-left: 5px;
                    margin-right: 5px;
                    background: var(--mobile-highlights-indicator-active);
                    opacity: 1;
                }
            }

            .highlights_carousel_item {
                border-radius: 4px;
                overflow: hidden;
                /*transition: all 0.35s ease-in-out;*/

                &.active {
                    border: 2px solid;
                    margin-top: -2px;
                }

                img {
                    width: 90px;
                }
            }
        }
    }
}
