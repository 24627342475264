.banner-node {
    width: 100%;
    margin-top: 1.2rem;
    margin-bottom: .8rem;

    .media {
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid var(--blue-50);
        transition: all .3s ease-in-out;
        aspect-ratio: 8;

        &:hover {
            cursor: pointer;
            opacity: .84;
        }

        img {
            width: 100%;
        }
    }
}

.layout-mobile {

    .banner-node {
        padding: 0 12px;
    }
}
