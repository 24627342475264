.publication-desktop {

    .p-sidebar {
        width: 50vw !important;
        height: 100vh !important;
        background: #fff;
        overflow: hidden;

        .p-sidebar-header {
            position: absolute;
            right: 0;
        }

        .p-sidebar-content {
            padding-bottom: 2rem;
        }

        h3 {
            padding: 14px 3rem;
            font-size: 1.35rem;
            font-weight: 500;
        }

        .pub-scroll {

            &>div:first-child {
                margin-right: -1rem !important;
                padding-right: 1rem;
            }

            &>div:last-child {
                right: -0px;
            }
        }

        .publication-body_hint {
            margin: 0 3rem 1.5rem;
            background: var(--blue-50);
            border: 1px solid var(--blue-100);
            border-radius: .75rem;
            padding: .9rem;
            font-size: .9rem;
        }

        .publication-body {
            padding: 0 3rem;

            .splide {
                padding-bottom: 2.2rem;

                .splide__slide {
                    border-radius: .8rem;
                    aspect-ratio: 3/2;
                    overflow: hidden;
                    text-align: center;

                    img {
                        height: 100%;
                        border-radius: .8rem;
                    }
                }

                .splide__pagination {

                    .splide__pagination__page {
                        background: var(--blue-100);

                        &.is-active {
                            background: var(--blue-200);
                        }
                    }
                }
            }

            .publication-media {
                width: 100%;
                aspect-ratio: 16/9;
                background-size: contain;
                background-position: center;
                border-radius: 6px;
                background-repeat: no-repeat;
            }

            .publication-content {
                a {
                    font-weight: 500;
                    text-decoration: none;
                    color: var(--blue-500);
                    transition: all .35s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .78;
                    }
                }
            }
        }
    }
}
