.fantasy-panel {
    z-index: 9999 !important;

    .p-sidebar {
        height: 60vh;
        background: #fff;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        height: 60vh !important;
        max-height: 450px !important;
        overflow: hidden;
        position: relative;

        iframe {
            height: 60vh;
            max-height: 450px !important;
            width: 100%;
        }

        .p-sidebar-header {
            position: absolute;
            right: 0;

            button {
                span {
                    font-size: 20px;
                }
            }
        }

        .p-sidebar-content {
            overflow: hidden;
        }
    }
}
