.p-dialog.video-box {

    .p-dialog-header {
        position: absolute;
        right: -4rem;
        top: -4rem;
        background: transparent;

        .p-dialog-header-icon:enabled {
            color: #343a40;
            background: #e9ecef;

            &:hover {
                background: transparent;
                color: #e9ecef;
            }
        }
    }

    .p-dialog-content {
        padding: 0;
        border-radius: 1rem;
        overflow: hidden;
        background: transparent;
        box-shadow: 4px 4px 120px #cfdffb6e;
    }
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}
