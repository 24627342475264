.suggest-list_item {
    display: flex;
    align-items: center;
    position: relative;

    .img {
        margin-right: 10px;
    }

    .info {
        font-size: 14px;
        line-height: 15px;

        span {
            font-size: 11px;
            padding-left: 2px;
            font-weight: 300;
            color: var(--bluegray-400);
        }
    }
}

.top.desktop {

    .account {
        padding-bottom: 0;
        width: auto;
    }
}

.account {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 100px;

    .user-widget {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 16px;

        .switch-control {
            display: flex;
            align-items: center;
            margin-top: 24px;
            padding-left: 16px;

            label {
                margin-left: 10px;
                color: var(--account-switch-inactive);
                font-size: 14px;
                transition: all 0.4s ease-in-out;
            }

            &.active {

                label {
                    color: var(--account-switch-label);
                }

                .p-inputswitch-slider {
                    background: var(--account-switch-bgd);
                }
            }
        }

        .favorites {
            margin-top: 30px;

            .top {

                .p-tag {
                    background: rgba(255, 255, 255, 0.09);
                    backdrop-filter: blur(3.9px);
                    -webkit-backdrop-filter: blur(3.9px);

                    .pi {
                        color: var(--yellow-500);
                    }
                }
            }

            .items {
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 8px;

                .item {
                    border-radius: 6px;
                    position: relative;
                    background: rgba(255, 255, 255, 0.09);
                    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(3.9px);
                    -webkit-backdrop-filter: blur(3.9px);
                    box-sizing: border-box;
                    padding: 44px 8px 18px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 19px;
                    transition: all 0.35s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .75;
                    }

                    .emblem {
                        position: absolute;
                        top: -10px;
                    }

                    .name {
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 12px;
                        color: var(--account-favorites-item-text);
                        height: 28px;
                        overflow: hidden;
                    }

                    .p-tag {
                        background: var(--account-favorites-tag-bgd);
                        position: absolute;
                        bottom: -9px;
                        padding: 2px 5px;
                        font-size: 11px;

                        .pi {
                            font-size: 11px;
                        }
                    }
                }
            }
        }

        .suggestions {
            margin-bottom: 1rem;

            .notice {
                margin: 1.5rem 0 .75rem;
                color: var(--global-filled-page-darken);
            }

            .card {

                .p-button-sm {
                    position: absolute;
                    bottom: -0.5rem;
                    right: 1rem;
                    padding: .35rem 1rem;
                    color: var(--blue-500);
                    background: var(--blue-50);
                }
            }
        }

        .card {
            border-radius: 6px;
            position: relative;
            background: rgba(255, 255, 255, 0.09);
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            width: 100%;
            padding: 12px;

            .binded-stats-link {
                margin: 18px -12px -12px;
                padding: 12px;
                text-align: center;
                background: var(--blue-800);
                color: var(--blue-100);
                transition: all .35s ease-in-out;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;

                &:hover {
                    cursor: pointer;
                    background: var(--blue-900);
                    color: var(--blue-200);
                }

                .pi {
                    margin-right: 8px;
                }
            }

            .p-inputtext {
                height: 2rem;
                margin-top: -4px;
                background: rgba(0, 0, 0, .15);
                border-color: transparent;
                color: var(--blue-100);

                &::placeholder {
                    color: var(--blue-100);
                }

                &:focus {
                    box-shadow: none;
                }

                &.p-inputmask {
                    margin-top: .5rem;
                }
            }

            .edit-trigger {
                position: absolute;
                width: 2rem;
                height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: rgba(0, 0, 0, .15);
                right: 10px;
                transition: all .35s ease-in-out;
                z-index: 2;

                &:hover {
                    transform: scale(.9);
                    background: rgba(0, 0, 0, .05);
                    cursor: pointer;
                }

                .pi {
                    font-size: 1rem;
                    color: var(--blue-50);
                }
            }

            .profile {
                display: flex;
                align-items: center;

                .avatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                        height: 100%;
                    }
                }

                .profile-info {
                    width: calc(100% - 50px);
                    box-sizing: border-box;
                    padding-left: 14px;
                    font-size: 14px;

                    .name {
                        text-transform: uppercase;
                        color: var(--global-filled-page-text);
                        margin-bottom: 4px;
                    }

                    .age {
                        color: var(--global-filled-page-darken);
                    }
                }
            }
        }
    }

    .welcome-flow {
        width: 80%;
        min-height: 320px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 2px 2px 30px #d0e1fd80;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 30px 12px 60px;

        .p-float-label {
            width: 100%;

            input, .p-inputnumber {
                width: 100%;
            }

            &:first-child {
                margin-bottom: 34px;
            }
        }

        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        &>div:not([role="button"]) {
            position: relative;
            z-index: 2;

            &.title {
                text-transform: uppercase;
            }

            &.cta {
                margin-top: 42px;
                text-align: center;
                font-size: 13px;
                line-height: 17px;
                color: var(--bluegray-600);

                i {
                    font-style: normal;
                    color: var(--blue-500);
                }
            }

            &.field {
                margin-top: 34px;
                width: 100%;

                .p-autocomplete {
                    width: 100%;

                    ul {
                        padding: 6px;
                        width: 100%;

                        .p-autocomplete-token {
                            font-size: 12px;
                            padding: 4px 7px;
                            margin-bottom: 4px;
                            margin-right: 4px;
                            background: var(--blue-100);
                            color: var(--blue-700);
                        }
                    }

                    input {
                        width: 100%;
                        background: transparent !important;
                        font-size: 13px;
                        padding-left: 4px;
                    }
                }
            }

            &.btns {
                position: absolute;
                left: 8px;
                right: 8px;
                bottom: 8px;
                text-align: right;

                .p-button {
                    border-radius: 11px;
                    padding: 7px 16px;
                    background: var(--blue-100);
                    border-color: var(--blue-100);
                    color: var(--blue-500);
                }
            }
        }
    }

    .form {
        width: 80%;
        min-height: 320px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 2px 2px 30px #d0e1fd80;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 60px;
        padding-left: 12px;
        padding-right: 12px;

        .switch-label {
            position: absolute;
            right: 0rem;
            top: -0.85rem;
            color: var(--global-filled-page-darken);
            display: flex;
            background: rgba(0, 0, 0, 0.11);
            border-radius: .5rem;
            padding: .35rem;

            .p-button {
                margin-top: 0 !important;
                padding: 0.75rem !important;
                border-radius: .5rem !important;
                background: none !important;
                border: none !important;
                transition: all .35s ease-in-out;

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }

                span {
                    font-size: 1.5rem !important;
                    color: var(--global-filled-page-darken);
                    transition: all .35s ease-in-out;
                }

                &.active {
                    background: rgba(255, 255, 255, .15) !important;

                    span {
                        color: #fff;
                    }
                }
            }
        }

        .p-error {
            width: 100%;
            margin-top: 6px;
            text-align: left;
            padding-left: 12px;
        }

        .title {
            text-transform: uppercase;
            font-weight: 400;
            color: var(--bluegray-700);
            margin-bottom: 80px;
        }

        .img {
            width: 90px;
            top: -40px;
            position: absolute;
        }

        .p-button {
            position: absolute;
            bottom: 8px;
            left: 8px;
            right: 8px;
            border-radius: 14px;
            justify-content: center;
        }

        .p-input-icon-right {

            .p-inputtext {
                width: 100%;
            }
        }

        .notice {
            text-align: left;
            padding-left: 12px;
            padding-right: 4px;
            font-size: 11px;
            margin-top: 14px;
            color: var(--bluegray-500);

            i {
                font-style: normal;
                color: var(--blue-500);
                transition: all .3s ease-in-out;

                &:hover {
                    cursor: pointer;
                    opacity: .7;
                }
            }
        }
    }
}
