body .p-sidebar-mask.account-sidebar._top {

    .p-sidebar {
        width: 100%;
        min-height: 30vh;
        max-height: 60vh;
        height: auto;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;

        .p-sidebar-content {

            .account {
                padding: 1rem;
                height: 100%;

                .user-widget {
                    padding: 0;
                    margin-top: -1rem;

                    .switch-control {
                        margin-top: 1.75rem;

                        label {
                            font-size: 16px;
                        }
                    }

                    .p-inputswitch {
                        width: 3.5rem;
                        height: 2.25rem;

                        .p-inputswitch-slider {
                            &::before {
                                width: 1.5rem;
                                height: 1.5rem;
                                top: 45%;
                            }
                        }
                    }
                }

                .form, .welcome-flow {
                    width: 100%;
                    min-height: unset;
                    height: 100%;
                    background: none;
                    backdrop-filter: blur(2px);
                    box-shadow: none;
                    padding: 0;
                    min-height: calc(30vh - 50px);
                    justify-content: flex-end;

                    img {
                        top: unset;
                        width: 150px;
                        left: -30px;
                        opacity: 0.35;
                    }

                    .title {
                        color: #fff;
                        font-size: 1.5rem;
                        margin-bottom: 3rem;
                    }

                    .p-float-label {
                        display: block;
                        width: 100%;
                        position: relative;

                        .p-inputtext {
                            width: 100%;
                            height: 54px;
                            background: rgba(255, 255, 255, 0.13);
                            border: none;
                            backdrop-filter: blur(8px);
                            text-align: center;
                            color: #fff;
                            font-size: 18px;
                            box-shadow: 0 4px 16px rgb(0 0 0 / 10%);

                            &:focus ~ label, &.p-filled ~ label {
                                opacity: 0;
                            }
                        }

                        label {
                            display: block;
                            width: 100%;
                            left: 0;
                            height: 54px;
                            top: 0;
                            margin-top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 18px;
                            color: #fff;
                            opacity: .75;
                        }

                        &.profile-info {
                            margin-bottom: 0 !important;

                            .p-inputtext, .p-inputnumber {
                                width: 100%;
                                height: 44px;
                                background: rgba(255, 255, 255, 0.13);
                                border: none;
                                backdrop-filter: blur(8px);
                                text-align: center;
                                color: #fff;
                                font-size: 18px;
                                box-shadow: 0 4px 16px rgb(0 0 0 / 10%);

                                &:focus ~ label, &.p-filled ~ label, &.p-inputwrapper-filled ~ label, &.p-inputwrapper-focus ~ label {
                                    opacity: 0;
                                }
                            }
                        }

                        &.age {
                            margin-top: 1rem;

                            label {
                                display: block;
                                width: 100%;
                                left: 0;
                                height: 44px;
                                top: 0;
                                margin-top: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 18px;
                                color: #fff;
                                opacity: .75;
                            }
                        }
                    }

                    .notice, .cta {
                        padding-left: 0;
                        padding: 0 1rem;
                        text-align: center;
                        font-size: 1.25rem;
                        color: #ddd;
                    }

                    .cta {
                        margin-top: 0;
                    }

                    button {
                        border-radius: 6px;
                        padding: 1.25rem;
                        font-size: 1.25rem;
                        background: var(--global-highlight-color);
                        border-color: var(--global-highlight-color);
                        position: relative;
                        margin-top: 1rem;
                        width: 100%;
                        left: unset;
                        right: unset;
                        bottom: unset;
                    }

                    .grecaptcha-badge {
                        display: none !important;
                    }
                }

                .welcome-flow {
                    &>div:first-child {
                        margin-top: 0;
                        position: absolute;
                        z-index: 0;
                    }

                    &> div:not([role=button]).field {
                        margin-top: 1rem;
                    }

                    .btns {
                        position: relative;
                        bottom: unset;
                        margin-top: 1rem;
                        width: 100%;
                        padding: 0;
                        left: unset;
                        right: unset;

                        button {
                            padding: 1.25rem;
                            color: #fff;
                            width: 100%;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }

                            span {
                                font-weight: normal;
                            }
                        }
                    }

                    .p-autocomplete ul {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 6px;
                        min-height: 44px;
                        height: auto;

                        .p-autocomplete-token {
                            font-size: 13px;
                            padding: 4px;
                            margin: 0;
                            height: 32px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            background: rgba(255, 255, 255, .11);
                            color: #eee;
                            box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
                        }

                        .p-autocomplete-input-token {
                            input {
                                text-align: center;
                                padding-left: 0;
                            }
                        }
                    }

                    .p-inputtext {
                        height: 44px;
                        background: rgba(255, 255, 255, 0.13);
                        border: none;
                        backdrop-filter: blur(8px);
                        color: #fff;
                        font-size: 16px;
                        box-shadow: 0 4px 16px rgb(0 0 0 / 10%);

                        &:focus ~ label, &.p-filled ~ label {
                            opacity: 0;
                        }

                        li {
                            padding-top: 0;
                            padding-bottom: 0;

                            input {
                                height: 32px;
                                font-size: 14px;
                                color: #fff;

                                &::placeholder {
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
