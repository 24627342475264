.tag-feed {
    margin: 0 8px -20px;
    background: #fff;
    position: relative;
    z-index: 2;
    border-radius: 12px;
    min-height: 60vh;

    .feed-card {
        border-radius: 12px;
        background: #fff;
        padding: 8px;
        margin-bottom: 8px;
        position: relative;

        .preview {
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            display: flex;
            align-items: center;
            border-radius: 8px;

            img {
                width: 100%;
            }
        }

        .title {
            position: absolute;
            height: auto;
            left: 8px;
            right: 8px;
            bottom: 8px;
            background: linear-gradient(to top, var(--blue-800), transparent);
            border-radius: 8px;
            padding: 14px 20px;
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            color: var(--blue-50);
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: 13px;
                font-weight: 300;
                margin-top: 1px;
            }
        }

        .category {
            position: absolute;
            top: 16px;
            right: 16px;
            background: #3b82f630;
            padding: 2px 5px;
            border-radius: 4px;
            color: var(--blue-100);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            font-size: 13px;
        }
    }
}
