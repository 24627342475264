.btn-pull {
    padding: 1em 1.5em;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: center;

    .p-tag {
        padding: 5px 7px 4px;
        margin: 0 4px 8px;
        transition: all 0.4s ease-in-out;
        display: inline-flex;

        &:hover {
            cursor: pointer;
            opacity: .65;
        }
    }

    &.mobile {

    }
}
