.publication-preview {
    width: 100%;
    margin-bottom: 30px;

    .publication-preview_media {
        width: 100%;
        aspect-ratio: 16/9;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: var(--widget-publication-preview-shadow) 2px 2px 25px;
        transition: all .35s ease-in-out;
        position: relative;

        .photos-qty {
            position: absolute;
            right: 10px;
            bottom: 10px;
            background: rgba(0, 0 , 0, .55);
            color: var(--blue-50);
            padding: 3px 9px;
            border-radius: 6px;
        }

        .play_btn {
            transition: all .35s ease-in-out;
        }

        &:hover {
            opacity: .9;
            cursor: pointer;
            box-shadow: rgb(59 130 246 / 45%) 2px 2px 15px;

            .play_btn {
                transform: scale(1.2);
            }
        }
    }

    .publication-preview_title {
        margin-top: 14px;
        padding-left: 20px;
        color: var(--bluegray-900);
    }
}
