.top {
    .p-button {
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    &.desktop {

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }
        }

        .surface {
            height: 64px;
            display: flex;
            justify-content: center;

            .banners-node {
                display: flex;
                align-items: center;

                &.lfl36 {
                    img {
                        max-width: 80px;
                        max-height: 50px;
                    }
                }

                div {
                    margin-right: 20px;

                    &.search {
                        width: 280px;
                    }
                }
            }

            .emblem {
                position: absolute;
                width: 120px;
                height: 168px;
                margin-top: 10px;
                left: calc((100% - 120px)/2);

                &:hover {
                    cursor: pointer;
                }

                img {
                    position: absolute;
                    width: 120px;
                    height: 120px;
                    left: 0;
                    top: 48px;
                    z-index: 99;
                }
            }

            .account {
                display: flex;
                align-items: center;

                .app-link {
                    margin-left: 20px;
                }
            }
        }

        .accent {
            height: 64px;

            .navi-group {
                display: flex;
                height: 64px;
                align-items: center;
                max-width: 680px;

                .p-skeleton {
                    background-color: transparent;
                }

                .app-link {
                    margin-right: 18px;
                    padding: 7px 14px;
                    border-bottom: none;
                    background: rgba(255, 255, 255, .04);
                    border-radius: 6px;
                    transition: all 0.4s ease-in-out;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    min-width: 93px;
                    max-width: 400px;
                    text-align: center;

                    &:hover, &.active {
                          background: rgba(0, 0, 0, .15);
                      }
                }

                &:nth-child(2) {

                    .app-link {
                        margin-right: unset;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    &.mobile {
        position: relative;

        &>div {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;

            &.accent {
                padding-top: 12px;
            }

            &.emblem {
                position: absolute;
                left: calc((100% - 82px)/2);
                top: 14px;
                height: 58px;

                img {
                    width: 58px;
                    height: 58px;
                }
            }
        }

        .surface {
            display: flex;
            justify-content: space-between;

            &.lfl36 {
                &>div {
                    img {
                        max-width: 70px;
                        max-height: 25px;
                        margin-right: 5px;
                    }
                }

            }

            &>div {
                display: flex;

                img {
                    height: 38px;
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    &>img {
                        margin-right: unset;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
