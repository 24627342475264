.mediablock {

    &.desktop {
        position: relative;
        display: flex;
        min-height: 400px;
        transition: height 0.4s linear;

        box-shadow: inset -1px 0px 20px rgb(0 0 0 / 10%);

        &.in-page {
            box-shadow: none;

            .empty {
                top: 20px;
                bottom: 20px;
                right: 20px;
                left: 10px;
                background: var(--blue-50);
                position: absolute;
                border-radius: 12px;
                display: flex;
                align-items: center;
            }

            .content-wrap {

                .content {
                    padding: 1.5rem 1.5rem 2rem !important;
                    min-height: 26rem !important;

                    .item-intro {

                        .item-content_date {
                            font-size: .9rem !important;
                            margin-bottom: .5rem !important;
                        }

                        .item-content_title {
                            font-size: 1.5rem !important;
                            line-height: 1.2 !important;
                        }

                        .tags {
                            margin-bottom: 2rem !important;

                            .tag-item {
                                font-size: .75rem !important;
                                padding: .2rem .4rem !important;
                            }
                        }
                    }
                }
            }

            .p-tabmenu {
                margin: .5rem 0 !important;

                .p-tabmenuitem {
                    a.p-menuitem-link {
                        font-size: .9rem !important;
                        padding: .25rem .75rem !important;

                        span {
                            color: var(--team-mediablock-menu-color) !important;
                            padding-bottom: .25rem !important;
                        }
                    }

                    &.p-highlight {
                        a.p-menuitem-link {
                            border-color: var(--global-highlight-color) !important;

                            span {
                                border-color: var(--global-highlight-color) !important;
                                color: var(--global-highlight-color) !important;
                            }
                        }
                    }
                }
            }
        }

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .item-wrap {
                width: 55%;
                height: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;

                .item-skeleton {
                    background-color: transparent;
                    border-radius: 20px;
                }

                .item {
                    width: 100%;
                    aspect-ratio: 16/9;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 25px;
                    position: relative;
                    transition: background .5s ease-in-out;

                    &.unloaded {
                        background-image: none !important;

                        .p-progress-spinner {
                            visibility: visible;
                            opacity: 1;
                        }

                        .click-cover {
                            visibility: hidden;
                        }
                    }

                    .p-progress-spinner {
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        transition: all .5s ease-in-out;
                        z-index: 90;
                        left: calc((100% - 100px)/2);
                        top: calc((100% - 100px)/2);
                    }

                    .click-cover {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        transition: all .35s ease-in-out;
                        background: rgba(0, 0, 0, .1);
                        border-radius: 25px;

                        &:hover {
                            cursor: pointer;
                            background: transparent;
                        }
                    }
                }
            }

            .content-wrap {
                width: 45%;
                box-sizing: border-box;
                padding: 0px 0 0px 50px;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: flex-end;

                .p-tabmenu {
                    margin-bottom: 12px;

                    .p-tabmenu-nav {
                        background: transparent;
                        border: none;

                        .p-tabmenuitem {

                            .p-menuitem-link {
                                background: transparent;
                                padding: 4px 15px 2px;
                                font-size: 14px;
                                font-weight: 600;
                                transition: all 0.4s ease-in-out;

                                &:focus {
                                    box-shadow: none;
                                    outline: none;
                                    border-color: transparent;
                                }

                                span {
                                    padding-bottom: 10px;
                                    border-bottom: 2px solid transparent;
                                    color: var(--top-mediablock-tab);
                                    transition: all 0.4s ease-in-out;
                                }
                            }

                            &.p-highlight {

                                .p-menuitem-link {

                                    span {
                                        border-bottom: 2px solid #fff;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }

                .p-skeleton {
                    background-color: transparent;
                }

                .category-row {
                    display: none;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    &.loaded {
                        justify-content: flex-start;

                        .category {
                            width: 30%;
                            margin-right: 3%;
                            margin-bottom: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-transform: uppercase;
                            border: 1px solid;
                            height: 30px;
                            transition: all 0.4s ease-in-out;

                            &:hover {
                                cursor: pointer;
                                opacity: .7;
                            }
                        }
                    }
                }

                .content {
                    background: #fff;
                    padding: 30px 22px 60px;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    box-shadow: 2px 2px 35px rgb(245 249 255 / 44%);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-height: 360px;
                    box-sizing: border-box;

                    .item-intro {

                        .item-content_date {
                            font-size: 13px;
                            line-height: 17px;
                            margin-bottom: 7px;
                        }

                        .item-content_title {
                            font-weight: 500;
                            font-size: 26px;
                            line-height: 27px;
                            // letter-spacing: 0.04em;
                            margin-bottom: 10px;

                            &.clickable {
                                transition: all .35s ease-in-out;

                                &:hover {
                                    cursor: pointer;
                                    opacity: .78;
                                }
                            }
                        }

                        .p-skeleton:first-child {
                            margin-bottom: 16px;
                        }

                        .tags {
                            display: flex;
                            flex-wrap: wrap;
                            margin-bottom: 30px;

                            .tag-item {
                                font-size: 12px;
                                margin-right: 6px;
                                margin-bottom: 4px;
                                background: var(--global-tag-background);
                                border: 1px solid var(--global-tag-border-color);
                                padding: 3px 9px;
                                border-radius: 5px;
                                font-weight: 500;
                                color: var(--global-tag-value);
                                transition: all 0.3s ease-in-out;
                                white-space: nowrap;
                                opacity: .75;

                                &:hover {
                                    cursor: pointer;
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .previews {
                        display: flex;

                        .preview-item {
                            height: 108px;
                            width: 63px;
                            border-radius: 10px;
                            background: url('https://files.amateum.com/assets/media_block_cover.png');
                            background-size: cover;
                            margin-right: 20px;
                            border: 3px solid;
                            opacity: .78;

                            &:active {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .stripes {
            position: absolute;
            z-index: 0;
            top: -64px;
            display: none;
            bottom: 0;
            left: 45%;
            right: 15%;

            div {
                height: 100%;
                width: 100%;
                position: absolute;
                clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%);

                &:first-child {
                    position: absolute;
                    right: -50px;
                }
            }
        }
    }

    &.mobile {
        position: relative;
        padding: 8px;
        padding-top: 0;
        background: linear-gradient(to bottom, #fff 80%, #272b34 95%) !important;

        .stories-nav {
            position: absolute;
            z-index: 2;
            left: 6px;
            right: 7px;
            top: 15px;
            height: 88px;
            // background: linear-gradient(180deg, #272B34 0%, rgba(41, 91, 172, 0) 100%);
            display: flex;
            align-items: center;

            .splide__slide:first-child {

                .stories-nav-item {
                    margin-left: 6px;
                }
            }

            .stories-nav-item {
                width: 68px;
                position: relative;
                margin-left: 14px;
                display: flex;
                justify-content: center;

                &>div {
                    width: 56px;
                    height: 56px;
                    background: #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                    padding: 3px;
                    transition: all 0.4s ease-in-out;

                    &>div {
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: center;
                    }
                }

                &>span {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 2;
                    background: #fff;
                    border-radius: 4px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 14px;
                    color: var(--blue-500) !important;
                    transition: all 0.5s ease-in-out;
                }

                &.active {
                    &>div {
                        background: var(--blue-500) !important;
                    }

                    &>span {
                        background: var(--blue-500) !important;
                        color: #fff !important;
                    }
                }
            }
        }

        .item {
            position: absolute;
            top: 0;
            left: 6px;
            bottom: 0;
            right: 6px;
            background-size: cover;
            background-position: center;
            z-index: 1;
            border-radius: 12px;
            // box-shadow: 2px 2px 8px rgba(245, 249, 255, .88) !important;


            .item-content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                box-sizing: border-box;
                padding: 20px 20px 30px;
                height: 230px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                background: linear-gradient(180deg, rgba(41, 91, 172, 0) 0%, #272B34 100%);

                .item-content_title {
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 25px;
                    letter-spacing: 0.04em;
                    color: var(--blue-100) !important;
                }

                .item-content_date {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 6px;
                    color: var(--blue-300) !important;
                }
            }
        }
    }
}
