.club-feed {
    margin: 0 -8px;
    padding: 8px;

    .feed-card {
        border-radius: 12px;
        background: #fff;
        box-shadow: 2px -1px 20px var(--blue-50);
        //padding: 8px;
        margin-bottom: 8px;
        position: relative;

        .preview {
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            display: flex;
            align-items: center;
            border-radius: 8px;

            img {
                width: 100%;
            }
        }

        .title {
            position: absolute;
            height: auto;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: linear-gradient(to top, var(--blue-800), transparent);
            border-radius: 8px;
            padding: .4rem .7rem .5rem;
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            color: var(--blue-50);
            font-size: .9rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span {
                font-size: .8rem;
                font-weight: 300;
                margin-top: 1px;
            }
        }

        .category {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background: #3b82f630;
            padding: 2px 5px;
            border-radius: 4px;
            color: var(--blue-100);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            font-size: 13px;
        }
    }

    .content-card {
        // margin-left: 8px;
        // margin-right: 8px;
        margin-bottom: 8px;
        box-shadow: 2px 2px 25px var(--blue-100) !important;
        border-radius: 12px;
    }

    .achievement-card {
        height: 130px;
        border-radius: 8px;
        margin: 0px 0px 4px;
        position: relative;
        background: rgba(255, 255, 255, 0.09);
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(3.9px);
        -webkit-backdrop-filter: blur(3.9px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 17px;

        .info {
            padding-right: 125px;

            .name {
                color: var(--blue-50);
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: .4px;
            }

            .team {
                font-size: 13px;
                font-weight: 300;
                color: var(--blue-200);
            }

            .description {
                margin-top: 16px;
                font-size: 13px;
                color: var(--blue-100);
            }

            .p-tag {
                position: absolute;
                bottom: -4px;
                font-size: 11px;
                line-height: 11px;
                padding: 2px 7px;
                background: var(--achievement-date-bgd);
            }
        }

        .avatar-shield {
            transform: scale(.42);
            position: absolute;
            right: 10px;
            transform-origin: 100% 50%;
            margin-top: -20px;
        }

        .achievement {
            position: absolute;
            right: 70px;
            bottom: -10px;
        }
    }
}
