.content-tabs {
    padding: 10px;

    .content-tabs_wrap {
        border-radius: 8px;
        padding: 0px 18px;
        height: 32px;
        display: flex;
        justify-content: center;

        .p-tabmenu .p-component {
            display: flex;
            margin-top: 2px;
        }

        .__blue .p-highlight a.p-menuitem-link {
            border-color: var(--blue-500);
            color: var(--blue-500);
        }

        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,
        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link,
        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:hover,
        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:active  {
            background: none;
            outline: none;
            box-shadow: none;
            padding: 0 15px 0 10px;
            height: 32px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;

            @media screen and (max-width: 396px) {
                font-size: 11px;
                padding: 0 13px 0 9px;
            }
        }

        .p-tabmenu .p-tabmenu-nav {
            background: none;
        }
    }
}
