.player-summary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 240px;
    box-sizing: border-box;
    padding-bottom: 16px;

    .p-skeleton {
        background: var(--player-summary-skeleton);

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(133, 174, 215, 0.2), rgba(255, 255, 255, 0));
        }
    }

    .title {
        text-transform: uppercase;
        color: var(--player-summary-title-light);
        font-size: 18px;
        letter-spacing: .6px;
        margin-bottom: 15px;
        font-weight: 600;
        transition: all 0.4s ease-in-out;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &.compact {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {

            .label {
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 600;
                letter-spacing: .5px;
                color: var(--player-summary-grid-value-color);
                position: relative;
                display: inline-block;

                .tooltip-trigger {
                    position: absolute;
                    right: -20px;
                    top: -5px;
                    width: 18px;
                    height: 18px;
                    background: var(--player-summary-grid-tooltip-bgd);
                    color: var(--player-summary-grid-tooltip-color);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.4s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        opacity: .5;
                    }

                    i {
                        font-size: 10px;
                    }
                }
            }

            .value {
                margin-top: 0px;
                margin-bottom: 12px;
                font-weight: 600;
                font-size: 25px;
                line-height: 25px;
                color: var(--player-summary-grid-value-light-color);
                transition: all 0.4s ease-in-out;

                span {
                    border-bottom: 2px solid var(--player-summary-grid-value-light-color);
                    transition: all 0.4s ease-in-out;
                }
            }
        }
    }
}
