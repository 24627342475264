.layout-desktop {

    .team-events {

        .events-grid {

            .team-events_item {

                .graph {
                    padding-right: 0;
                }
            }
        }
    }
}

.team-events {
    border-top: 1px solid var(--team-events-border-color);
    padding-top: 18px;
    height: calc(100% - 367px);
    // background: linear-gradient(to bottom, var(--blue-500), var(--blue-400));
    // background: linear-gradient(to top, var(--blue-400), var(--blue-600));
    padding-bottom: 18px;

    .title {
        text-transform: uppercase;
        font-weight: 500;
        // text-align: center;
        color: var(--team-events-title);
    }

    .events-grid {
        position: relative;

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 20px;
            width: 70px;
            z-index: 10;
        }

        &::before {
            left: 0;
            background: linear-gradient(to right, var(--team-events-splide-curtain) 30%, transparent);
        }

        &::after {
            right: 0;
            background: linear-gradient(to left, var(--team-events-splide-curtain) 30%, transparent);
        }

        .splide__track {
            padding-bottom: 2em;
            padding-top: 20px;
            //padding-left: 20px !important;
        }

        .splide__arrow {
            z-index: 11;
            background: var(--team-events-splide-arrow);

            svg {
                fill: var(--team-events-splide-arrow-svg);
            }
        }

        .splide__pagination {
            bottom: -10px;

            .splide__pagination__page {
                width: 12px;
                border-radius: 5px;
                background: var(--team-events-splide-pagination);
                opacity: 1;
            }

            .splide__pagination__page.is-active {
                width: 18px;
                border-radius: 5px;
                margin-left: 5px;
                margin-right: 5px;
                background: var(--team-events-splide-pagination-active);
                opacity: 1;
            }
        }

        .team-events_item {
            position: relative;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.09), transparent);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            height: 320px;
            border-radius: 8px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;

            .player {
                font-weight: 700;
                letter-spacing: .6px;
                text-transform: uppercase;
                color: #fff;
                font-size: 16px;
                height: 24px;
                padding-left: 17px;
                padding-right: 17px;
                padding-bottom: 17px;
                white-space: nowrap;
                border-radius: 6px;
                display: flex;
                align-items: center;
                padding-top: 40px;
            }

            .graph {
                display: flex;
                justify-content: space-around;
                position: relative;
                padding-right: 38px;
                padding-bottom: 40px;

                .achievement {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
            }

            .avatar {
                width: 96px;
                height: 96px;
                background: var(--team-events-avatar-bgd);
                border: 3px solid rgba(255, 255, 255, 0.39);
                box-shadow: 1px 1px 15px var(--team-events-avatar-shadow);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                overflow: hidden;

                img {
                    height: 126px;
                    filter: drop-shadow(1px 0 0 white)
                            drop-shadow(0 1px 0 white)
                            drop-shadow(-1px 0 0 white)
                            drop-shadow(0 -1px 0 white);

                    &.empty {
                        opacity: .15;
                    }
                }
            }

            .content {
                padding: 0 40px 50px;
                text-align: center;
                color: var(--team-events-content);

                b {
                    font-weight: 500;
                    color: var(--blue-50);
                }
            }

            .date {
                position: absolute;
                bottom: -8px;
                background: var(--team-events-date-bgd);
                padding: 4px 16px;
                font-size: 11px;
                color: var(--team-events-date-color);
                box-shadow: 1px 1px 15px var(--team-events-date-bgd);
            }
        }
    }
}
