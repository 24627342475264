html {
    @media only screen and (min--moz-device-pixel-ratio: 1.1),
        (-o-min-device-pixel-ratio: 10/9),
        (-webkit-min-device-pixel-ratio: 1.1),
        (min-device-pixel-ratio: 1.1) {

        font-size: 14px;
    }

    @media only screen and (min--moz-device-pixel-ratio: 1.25),
        (-o-min-device-pixel-ratio: 5/4),
        (-webkit-min-device-pixel-ratio: 1.25),
        (min-device-pixel-ratio: 1.25) {

        font-size: 12px;
    }

    @media only screen and (min--moz-device-pixel-ratio: 1.5),
        (-o-min-device-pixel-ratio: 3/2),
        (-webkit-min-device-pixel-ratio: 1.5),
        (min-device-pixel-ratio: 1.5) {

        font-size: 11px;
    }
}

@media only screen and (min--moz-device-pixel-ratio: 1.1),
    (-o-min-device-pixel-ratio: 10/9),
    (-webkit-min-device-pixel-ratio: 1.1),
    (min-device-pixel-ratio: 1.1) {
        .layout-desktop {

            .top.desktop {

                .container {
                    width: 94%;
                }

                .surface {
                    height: 4.5rem;

                    .banners-node {
                        img {
                            height: 4.5rem;
                        }
                    }

                    .account {
                        .p-button {
                            font-size: 1rem
                        }
                    }
                }

                .emblem {
                    width: 9rem;

                    img {
                        width: 9rem;
                        height: 9rem;

                        @media only screen and (min--moz-device-pixel-ratio: 1.5),
                            (-o-min-device-pixel-ratio: 3/2),
                            (-webkit-min-device-pixel-ratio: 1.5),
                            (min-device-pixel-ratio: 1.5) {

                            margin-top: 1rem;
                        }
                    }
                }

                .accent {
                    height: 4.5rem;

                    .navi-group {
                        height: 4.5rem;

                        &:last-child {

                            .app-link {
                                margin-left: 1rem;
                                margin-right: unset;
                            }
                        }

                        .app-link {
                            font-size: 1rem;
                            margin-right: 1rem;
                        }
                    }
                }
            }

            .mediablock.desktop {

                .container {
                    width: 94%;

                    .content-wrap {

                        .p-tabmenu {

                            .p-tabmenu-nav {
                                .p-tabmenuitem {

                                    .p-menuitem-link {
                                        font-size: 1rem;

                                        span {
                                            padding-bottom: .5rem;
                                        }
                                    }
                                }
                            }
                        }

                        .content {
                            padding-bottom: 2rem;
                            min-height: 30rem;

                            .item-intro {

                                .item-content_date {
                                    font-size: 1rem;
                                }

                                .item-content_title {
                                    font-size: 1.8rem;
                                }

                                .tags {

                                    .tag-item {
                                        font-size: .9rem;
                                    }
                                }
                            }

                            .previews {

                                .items-nav {

                                    .items-nav_item {

                                        .title {
                                            font-size: .9rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .matches-carousel.extended {

                .container {
                    width: 94%;

                    .matches-carousel_list {
                        padding: 0;

                        .matches-carousel_list_item {

                            .matches-carousel_list_item_datetime {
                                text-align: left;
                            }

                            .matches-carousel_list_item_info {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }

            .desktop-section {

                .container {
                    width: 94%;

                    .feed.desktop {

                         .feed_list {

                             .feed_list_item {

                                 .feed_list_item_content {

                                     .feed_list_item_content_title {
                                         font-size: 1rem;
                                     }

                                     .feed_list_item_content_date {
                                         font-size: .8rem;
                                     }
                                 }
                             }
                         }
                    }
                }
            }

            .stats-block.desktop {
                width: 100% !important;
                padding: 0 3%;

                &.blured {

                    .overlay {

                        .overlay-text {
                            margin: 0 20rem 3rem;
                            font-size: 1.4rem;
                        }

                        .btn-pull {
                            margin: 0 20rem;

                            .p-tag {
                                font-size: .9rem;
                            }
                        }
                    }
                }

                .stats-card {
                    .stats-card_inner {

                        .stats-card_inner_top {

                            .stats-card_inner_top__title {
                                font-size: .9rem;
                                left: -1px;
                            }

                            .stats-card_inner_top__value {
                                font-size: 5rem;
                                padding: 2.5rem 1rem 0;
                                line-height: 6rem;
                            }

                            .stats-card_inner_top__emblem {
                                width: 8rem;
                                height: 8rem;

                                div {
                                    width: 11rem;
                                    height: 11rem;
                                    margin-left: -2.5rem;
                                    margin-bottom: -4rem;
                                }
                            }

                            .stats-card_inner_top__player {
                                padding-bottom: 1rem;

                                &>div.alterfont {
                                    font-size: 1.1rem;
                                }

                                &>div:not(.alterfont) {
                                    font-size: .9rem;
                                }
                            }

                            .stats-card_inner_top__avatar {
                                height: 110%;

                                &._team {
                                    height: 80%;

                                    &>div {
                                        background-size: 7.5rem;
                                        background-position: bottom 1rem right .9rem;
                                    }
                                }
                            }
                        }

                        .stats-card_inner_list {

                            .stats-card_inner_list_row {
                                height: 3.1rem;

                                .stats-card_inner_list_player {
                                    font-size: .9rem;

                                    &>div:nth-child(2) {
                                        font-size: .8rem;
                                    }
                                }

                                .stats-card_inner_list_value {
                                    font-size: 1.1rem;
                                }
                            }
                        }
                    }
                }
            }

            .highlights.desktop {

                .highlights_container, .highlights_title, .previews {
                    width: 94%;
                }

                .highlights_title {
                    font-size: 1.75rem;
                    padding-top: 2rem;
                    margin-bottom: 2rem;
                }

                .previews {

                    .preview-item {

                        .preview-item_media {
                            width: 100%;
                            height: unset !important;
                            aspect-ratio: 16/9;
                        }
                    }
                }
            }

            .footer.desktop {

                .container {
                    width: 94%;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;

                    .footer_contacts {
                        font-size: 1rem;

                        .footer_contacts_emblem {
                            img {
                                height: 6rem;
                                width: 6rem;
                            }
                        }
                    }

                    .footer_socials {

                        .footer_socials_item {
                            width: 2.5rem;
                            height: 2.5rem;
                            background-size: cover;
                            margin-bottom: 0;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }

                    .footer-stores {

                        &>div {
                            width: 12rem;
                            aspect-ratio: 180/52;
                            background-size: cover;
                            height: unset;
                        }
                    }
                }
            }
        }
}

@media only screen and (min--moz-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3/2),
    (-webkit-min-device-pixel-ratio: 1.5),
    (min-device-pixel-ratio: 1.5) {

    .tournament.desktop {

        .container {
            width: 94%;

            .content-btns {

                .content-btn {
                    font-size:  1.25rem;
                    width: auto;
                    height: 4rem;
                    padding: 0 1.5rem;

                    .pi {
                        font-size: 1.6rem;
                    }
                }

                .icons-navi {
                    .icons-navi_item {
                        height: 4rem;

                        .icons-navi_item_icon {
                            width: 1.7rem;
                            height: 1.7rem;
                            -webkit-mask-size: 1.7rem;
                        }

                        span {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    .match.desktop {

        .container {
            width: 94%;

            .rosters-content .roster-cell.__away .roster-list_item::before {
                right: -50px;
            }

            .rosters-content .roster-cell.__home .roster-list_item::before {
                left: -50px;
            }
        }
    }

    .player.filled-page {

        .container {
            width: 94%;

            &>.col {

                .profile {

                    .avatar-shield {
                        transform: scale(.9);
                    }

                    .info {
                        margin-top: 3rem;

                        .age {
                            font-size: 1rem;
                        }
                    }

                    .squads {

                        .section {

                            .label {
                                font-size: 1.1rem;
                                padding: .7rem 0;
                            }

                            .items {
                                margin-bottom: .5rem;

                                .item {
                                    height: 3.5rem;

                                    .emblem {
                                        margin-right: .5rem;
                                    }

                                    span {
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .content {

                .focus {

                    .feed {
                        margin-left: -50px;
                        margin-right: -20px;
                    }
                }
            }
        }
    }
}
