.ytp-impression-link {
    display: none !important;
}

.video-wrap {
    position: relative;

    .play_btn {
        position: absolute;
        top: calc((100% - 52px)/2);
        left: calc((100% - 52px)/2);
        transition: all .35s ease-in-out;
        z-index: 100;

        &:hover {
            cursor: pointer;
            opacity: .75;
        }
    }

    &.fs {

        .video {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            border-radius: 0;
            aspect-ratio: unset;
            z-index: 100;
        }

        .mute_btn {
            position: fixed;
            bottom: 20px;
            left: 10vw;
            z-index: 101;
        }
    }

    .video {
        border-radius: 8px;
        aspect-ratio: 16/9;
        overflow: hidden;
    }

    .mute_btn {
        position: absolute;
        bottom: 10px;
        left: 10px;

        .p-button {
            padding: 8px;
            height: auto !important;
            width: auto;
            transition: all 0.4s ease-in-out;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.off {

            .p-button {
                background: rgba(0, 0, 0, .45);
                backdrop-filter: blur(3px);
                border-color: rgba(255, 255, 255, .15);
            }
        }

        &.on {

            .p-button {
                background: rgba(0, 0, 0, .25);
                backdrop-filter: blur(3px);
                border-color: rgba(255, 255, 255, .15);
            }
        }
    }
}

.notice {
    text-align: center;
    margin-top: 6px;

    .p-tag {
        font-size: 12px;
        font-weight: normal;
        background: var(--bluegray-50);
        color: var(--bluegray-300);
    }
}
