html {
    font-size: 14px;

    @media screen and (max-width: 375px) {
        font-size: 13px;
    }

    @media screen and (max-width: 320px) {
        font-size: 12px;
    }
}

.stack {
    // min-height: calc(100vh - 88px);
    display: flex;
    flex-direction: column;
    margin-top: -1px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: var(--top-search-accent);
        height: 200px;
        visibility: hidden;
        opacity: 0;
    }

    .area-switch {
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 1rem;
        transition: position .5s ease-in-out;

        .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
            background: transparent;
        }

        .p-tabmenu {
            background: var(--mobile-tabmenu-bgd);
            height: 3rem;
            padding: 0 6px;
            z-index: 3;
            border-radius: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 20px var(--mobile-tabmenu-shadow);

            .p-tabmenu-nav {
                background: transparent;
                border: none;
                height: 100%;
                display: flex;
                align-items: center;

                .p-tabmenuitem {
                    height: 80%;
                    border-radius: 20px;
                    transition: all 0.4s ease-in-out;

                    .p-menuitem-link {
                        background: transparent;
                        padding: 0px 15px 2px;
                        font-size: 1rem;
                        font-weight: 600;
                        transition: all 0.4s ease-in-out;
                        border-color: transparent;
                        border-width: 0 0 0 0;
                        border-radius: 0px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                            border-color: transparent;
                        }

                        span {
                            padding-bottom: 7px;
                            transition: all 0.4s ease-in-out;
                            padding-bottom: 0;
                            color: var(--mobile-tabmenu-text);
                        }
                    }

                    &.p-highlight {
                        background: var(--mobile-tabmemu-highlight);

                        .p-menuitem-link {
                            border-color: var(--blue-600);

                            span {
                                color: #fff;
                                color: var(--blue-50);
                            }
                        }
                    }
                }
            }
        }
    }

    .stack-content {
        margin-top: -3rem;
        position: relative;
        min-height: 220px;

        &::before {
            visibility: visible;
            opacity: 0;
        }

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height:50px;
            background: var(--top-search-accent);
            // background: linear-gradient(to bottom, var(--blue-700) 50%, transparent);
            z-index: 0;
        }

        .mediablock {

            &.mobile {

                .item {
                    transition: all .3s linear;
                }
            }
        }

        .tournament {
            background: #fff;
            z-index: 2;
            position: relative;
            margin: -4px 8px 0;
            width: calc(100% - 16px) !important;
            border-radius: 12px;
            padding-top: 34px;

            .icons-navi {
                padding: 0;
                justify-content: center;

                .icons-navi_item {
                    width: 48px;
                    height: 48px;

                    .icons-navi_item_icon {
                        width: 22px;
                        height: 22px;
                        -webkit-mask-size: 22px;
                    }
                }
            }

            .content {
                padding: 0;

                .standings, .rankings {
                    width: 100% !important;

                    .standings-head, .rankings-head {
                        height: 42px;
                        font-size: 13px;
                        background: transparent !important;
                    }

                    .standings-row, .rankings-row {
                        position: relative;
                        border-top: none !important;

                        &::after {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 5px;
                            height: 1px;
                            background: linear-gradient(to left, #e7f0ff, transparent);
                            content: "";
                        }

                        .rankings-player_info {
                            padding-left: 6px;
                        }

                        .rankings-values_item {
                            white-space: nowrap;
                        }
                    }

                    .standings-row:not(.standings-head) {
                        font-size: 13px;

                        &:last-child {

                            &::after {
                                display: none;
                            }
                        }

                        .standings-row_team {

                            .__pos {
                                width: 36px;
                            }
                        }
                    }

                    &.chess {
                        margin-bottom: 20px;

                        .chess-teams-list {

                            .standings-row {
                                border-bottom: none !important;
                            }

                            &::before {
                                display: none;
                            }
                        }

                        .chess-body {

                            &>div {
                                max-height: unset !important;

                                &>div {
                                    max-height: unset !important;
                                }
                            }

                            .standings-row {
                                border-bottom: none !important;

                                .chess-cells {
                                    position: relative;

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        height: 1px;
                                        right: 0px;
                                        background: linear-gradient(to left, #e7f0ff 95%, transparent);
                                    }
                                }


                                .standings-row_values {
                                    width: auto !important;
                                    position: relative;
                                    height: 100%;
                                    align-items: center;

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        height: 1px;
                                        right: 0px;
                                        background: linear-gradient(to left, #e7f0ff 95%, transparent);
                                    }
                                }

                                // &::after {
                                //     right: 0px;
                                //     background: linear-gradient(to left, #e7f0ff 95%, transparent);
                                // }
                            }
                        }
                    }
                }
            }
        }

        .multi-standings {
            padding-left: 0;
            margin: 0 8px;
            background: #fff;
            z-index: 2;
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            padding-top: 30px;
            padding-bottom: 12px;
            margin-bottom: -32px;

            .p-dropdown {
                border-left: none;
                border-right: none;
                border-radius: 0;
            }

            .standings-head {
                height: 42px;
            }

            .standings-row:not(.standings-head) {
                font-size: 13px;

                &:last-child {

                    &::after {
                        display: none;
                    }
                }
            }

            .content-body {

                .p-skeleton {
                    height: 400px !important;
                    width: calc(100% - 16px) !important;
                    margin: 14px 8px;
                }
            }
        }

        .top-lineup {
            position: relative;
            z-index: 2;
            margin: -8px 8px 20px;
            border-radius: 12px;
            padding: 14px 8px;
            padding-top: 34px;

            .title {
                left: 0px;
                top: -2px;
                right: 0px;
                text-align: center;
                background: none !important;
            }

            .content-card {
                box-shadow: none !important;

                .p-dropdown {
                    border-color: var(--widget-lineup-line);

                    .p-inputtext {
                        color: var(--mobile-widget-lineup-value);
                    }

                    .pi-chevron-down {
                        color: var(--mobile-widget-lineup-value);
                    }
                }

                .lineup {

                    &::before {
                        background: var(--statsblock-lineup-line);
                    }
                }
            }
        }

        .achievements-carousel {
            margin: 20px 8px;
        }

        .stats-block {

            .overlay-text {
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.3;
                margin: 0 1rem 1rem;
                padding: 1rem;
            }

            .btn-pull {
                padding: 0 1.2rem;
            }
        }

        &.club-stack {
            padding: 0 8px;

            .club-top {
                background: #fff;
                border-radius: 12px;
                position: relative;
                z-index: 2;
                padding: 0 12px;

                .club-top_primary {
                    padding-left: 16px;

                    .club-top_name {
                        text-transform: uppercase;
                        font-weight: 500;
                        color: var(--bluegray-700);
                        margin-bottom: 2px;
                    }

                    .club-top_location {
                        color: var(--bluegray-500);
                        font-weight: 300;
                        font-size: 14px;
                    }
                }
            }

            .mobile-matches {
                padding-top: 0;
                background: transparent;
                margin: 0;
            }

            .team-standings {
                padding: 0;
                margin-top: 8px;
                position: relative;

                .p-dropdown {
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    width: 260px;
                    z-index: 3;
                    height: 36px;

                    .p-inputtext {
                        height: 36px;
                        font-size: 13px;
                        padding-left: 12px;
                    }
                }

                .standings-cell {
                    font-size: 13px;
                }

                .standings-row_team {

                    .__pos {
                        width: 30px;
                        padding-left: 8px;
                    }
                }

                .standings-row_values {

                    .__val {
                        width: 44px;
                    }
                }
            }

            .team-players {
                padding: 8px;
                margin-top: 8px;
                border-top: none;
                border-left: none;
                background: #fff;
                border-radius: 12px;

                .title {
                    font-size: 13px;
                    padding: 6px;
                }

                .player {
                    width: 166px;
                    padding-left: 6px;
                    box-sizing: border-box;
                    font-size: 12px;
                }

                .values {
                    width: calc(100% - 166px);
                }

                .row {

                    &:last-child {

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        &.player-stack {

            .profile {
                background: #fff;
                border-radius: 12px;
                margin: 8px;
                z-index: 2;
                position: relative;
                padding-top: 25px;
                margin-top: -10px;
                box-sizing: border-box;
                position: relative;
                height: 250px;

                @media screen and (max-width: 375px) {
                    height: 220px;
                }

                .avatar-shield {
                    transform: scale(0.7);
                    transform-origin: 0 0;
                    // margin-left: calc(((100% - 260px)/2)*1.6);

                    @media screen and (max-width: 375px) {
                        transform: scale(0.6);
                        margin-top: 25px;
                    }
                }

                .info {
                    position: absolute;
                    left: 40%;
                    top: 0;

                    @media screen and (max-width: 375px) {
                        padding-left: 28px;
                    }
                }

                .squads {
                    display: none;
                }
            }

            .player-summary {
                background: var(--global-filled-page);
                margin-top: -24px;
                padding: 70px 28px 54px;
                height: auto;
            }

            .position-map {
                margin: -24px 8px 0;
                background: #fff;
                border-radius: 12px;

                .title {
                    text-align: center;
                    padding-top: 28px;
                }
            }

            .feed {
                margin-left: 8px;
                margin-right: 8px;
                border-radius: 12px;
                position: relative;
                z-index: 2;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;

                .feed-card {
                    margin-bottom: 12px;
                    box-shadow: 2px 2px 11px var(--blue-50);

                    &:first-child {
                        padding-top: 24px;
                        background: #fff;
                    }

                    .card-content {
                        flex-wrap: wrap;

                        .selection-item {
                            flex-direction: column;

                            &>div {
                                width: 100%;

                                &.info {
                                    text-align: center;

                                    .title {
                                        justify-content: center;
                                    }

                                    .p-tag {
                                        font-size: 1rem;
                                    }
                                }

                                &.selection-body {
                                    padding: 0;
                                    padding-bottom: .75rem;

                                    .lineup .lineup-row .lineup-row-item .lineup-row-item_name {
                                        font-size: .75rem;
                                    }

                                    .lineup .lineup-row .lineup-row-item .lineup-row-item_num {
                                        width: 2rem;
                                        height: 2rem;
                                    }

                                    .lineup .lineup-row .lineup-row-item.highlight {

                                        .lineup-row-item_name {
                                            font-weight: 500;
                                        }

                                        .lineup-row-item_num {
                                            width: 2.75rem;
                                            height: 2.75rem;
                                            background: var(--blue-600);

                                            .pi {
                                                font-size: 1.1rem;
                                                color: var(--global-highlight-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &::after {
                            content: none;
                        }

                        .body {
                            width: 100%;

                            .match {
                                width: 100%;
                                flex-direction: row;
                                height: auto;
                                position: relative;
                                border-right: none;

                                &.played {
                                    flex-direction: row-reverse !important;

                                    .score {
                                        position: absolute;
                                        right: 15px;
                                        bottom: 10px;
                                        height: auto;
                                        justify-content: flex-start;
                                    }

                                    .opposer-body {
                                        padding-top: 4px;
                                    }
                                }

                                &::after {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    height: 1px;
                                    content: "";
                                    background: linear-gradient(to left, transparent, var(--blue-100));
                                    bottom: 0;
                                }

                                .meta {
                                    width: 35%;
                                    position: relative;
                                }

                                .opposer {
                                    width: 65%;
                                }
                            }
                        }

                        .col {
                            width: 100%;
                            flex-direction: row;
                            box-sizing: border-box;
                            padding: 20px 12px;

                            .fantasy_match-widget {
                                width: 40%;
                            }

                            .matches-balance {
                                width: 60%;
                                box-sizing: border-box;
                                padding-left: 20px;
                            }

                            .meta-block_item {
                                margin-top: 0;
                                margin-bottom: 10px;
                            }

                            .meta-block_badge {
                                position: absolute;
                                top: -44px;
                                left: 52%;
                            }
                        }
                    }
                }
            }
        }

        &.match-stack {
            margin: -58px 8px -20px;
            background: #fff;
            position: relative;
            z-index: 2;
            border-radius: 12px;
            overflow: hidden;

            &::after {
                content: none;
            }

            .match-main {
                display: flex;
                padding-top: 50px;
                width: 100%;
                padding-bottom: 30px;
                position: relative;

                &::after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    height: 1px;
                    content: "";
                    background: linear-gradient(to left, transparent, var(--blue-100));
                    bottom: 0;
                }

                .match-main_team {
                    width: calc((100% - 116px)/2);
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .match-team_name {
                        text-transform: uppercase;
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--bluegray-800);
                        margin-top: 6px;
                        padding: 0 12px;
                        text-align: center;
                    }
                }

                .match-main_score {
                    width: 116px;
                    position: relative;

                    .shootout {
                        position: absolute;
                        bottom: -20px;
                        font-size: .9rem;
                        text-align: center;
                        left: 0;
                        right: 0;
                        color: var(--bluegray-800);
                    }

                    .state-indicator {
                        top: unset;
                        bottom: -20px;
                    }

                    .match-main_score_cell {
                        margin-top: 0;
                        width: 54px;
                        height: 68px;
                        font-size: 38px;

                        &::before {
                            height: 34px;
                        }
                    }
                }
            }

            .match-info_grid {

                .match-general_info {
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    padding: 14px;
                    flex-direction: column;

                    .match-staff {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1rem;
                        color: var(--bluegray-800);

                        &>div {
                            display: flex;
                            min-height: 2rem;
                            align-items: center;
                            border-bottom: 1px solid var(--blue-50);

                            &:last-child {
                                border-bottom: none;
                            }

                            &>div {
                                width: 50%;

                                &:first-child {
                                    color: var(--bluegray-500);
                                }

                                &:last-child {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    &>div:first-child {
                        display: flex;
                        flex-wrap: wrap;

                        div {
                            width: 50%;
                            font-size: 13px;
                            font-weight: 300;
                            color: var(--bluegray-900);
                            margin-bottom: .25rem;

                            &:nth-child(even) {
                                text-align: right;
                            }
                        }
                    }
                }

                .fantasy_match-widget {
                    padding: 40px;
                }

                .matches-balance {
                    padding: 30px 14px;
                    border-radius: 12px;
                    position: relative;

                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        content: "";
                        background: linear-gradient(to right, transparent, var(--blue-100));
                        top: 0;
                    }

                    .mean {
                        text-align: center;
                        margin-bottom: 14px;
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--bluegray-900);
                    }
                }
            }

            .match-events_grid {
                border: none;

                .p-timeline-event {

                    .events-item_body_primary {

                        span {
                            font-size: 12px;
                            width: 100%;
                            padding: 0;
                            letter-spacing: 0;
                        }
                    }

                    .events-item_body_secondary {
                        padding: 0;
                    }

                    .event-icon {
                        margin-left: 1px;
                    }

                    &:first-child {
                        padding-top: 30px;

                        .p-timeline-event-separator {
                            height: 90px;
                        }

                        .p-timeline-event-connector::after {
                            top: 37px;
                        }
                    }
                }
            }

            .roster-pos-switcher {
                position: absolute;
                top: 410px;
                left: 0;
                right: 0;
                text-align: center;
                z-index: 5;
            }

            .rosters-content {
                width: 200%;
                display: flex;
                transition: all 0.5s ease-in-out;
                position: relative;

                &.translated {
                    transform: translateX(-50%);
                }

                .roster-cell {
                    width: 50%;

                    .lineup {
                        margin-bottom: 40px;

                        &::before {
                            background: var(--statsblock-lineup-line);
                        }
                    }

                    .roster-list {
                        padding: 15px 15px 10px;
                        box-sizing: border-box;
                        width: 100% !important;

                        .roster-list_item {
                            display: flex;
                            position: relative;
                            height: 33px;
                            align-items: center;
                            font-size: 14px;
                            position: relative;
                            color: var(--bluegray-800);

                            .p-tag {
                                position: absolute;
                                background: var(--orange-500);
                                font-size: .9rem;
                                width: 4.3rem;
                                right: 30px;

                                span {
                                    color: var(--orange-50);
                                    width: unset;
                                }

                                .pi {
                                    margin-right: 0;
                                    width: auto;
                                }
                            }

                            span {
                                width: 20px;
                                text-align: center;
                                margin-right: 8px;
                            }

                            &::after {
                                position: absolute;
                                left: 0;
                                right: 0;
                                height: 1px;
                                content: "";
                                background: linear-gradient(to left, transparent, var(--blue-100));
                                bottom: 0;
                            }

                            &:last-child {

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &._match {

        &+.footer.mobile {
            padding-top: 44px;
        }
    }

    &._tournament {

        .top-lineup {
            margin-bottom: -40px;
        }

        .stats-block {
            padding-bottom: 100px;

            .splide__pagination {
                justify-content: flex-start;
                bottom: -32px;
                z-index: 5;
                padding-left: 20px;
                width: 100vw;
                left: calc((100% - 100vw)/2);

                .splide__pagination__page {
                    width: 10px;
                    border-radius: 5px;
                    background: var(--blue-100);
                    opacity: 1;
                    height: 7px;
                }

                .splide__pagination__page.is-active {
                    width: 17px;
                    border-radius: 5px;
                    margin-left: 5px;
                    margin-right: 5px;
                    background: var(--blue-50);
                    opacity: 1;
                }
            }

            .stats-card {
                margin-bottom: 0;

                .stats-card_inner {
                    box-shadow: 2px 2px 15px var(--blue-800);
                    height: 100%;

                    .stats-card_inner_top__value {
                        padding-top: 26px;
                        font-size: 70px;
                    }

                    .stats-card_inner_list_row .stats-card_inner_list_player > div:nth-child(2) {
                        font-weight: 400;
                    }

                    .stats-card_inner_top__emblem {
                        opacity: 0.15;
                    }

                    .stats-card_inner_top__avatar {

                        & > div {

                            &._empty {
                                opacity: 0.2;
                                background-size: 80%;
                                background-position: bottom right;
                            }
                        }
                    }

                    .stats-card_inner_top__avatar._team {
                        height: 100%;

                        & > div {
                            background-size: 100px;
                            background-position: center;
                        }
                    }
                }
            }
        }

        .achievements-carousel {
            margin-top: -44px;

            .title {
                left: 10px;
            }
        }
    }

    &._scrolled {

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: var(--top-search-accent);
            height: 200px;
            visibility: visible;
            opacity: 1;
        }

        .area-switch {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            background: transparent !important;
            height: 30px;
        }

        .stack-content {
            margin-top: 40px;

            .mediablock {

                &.mobile {

                    .item {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
