.team-players {
    border-top: 1px solid #f3f3fb;
    border-left: 1px solid #f3f3fb;
    height: 100%;
    padding: 10px 0 24px 24px;

    .top {
        display: flex;
        align-items: center;

        .title {
            text-transform: uppercase;
            margin-right: 20px;
        }

        .select-tour {
            width: 50%;

            .p-dropdown {
                width: 100%;
                height: 44px;
                font-size: 14px;
                border-radius: 0;
                border: none;
                background: linear-gradient(to top, #f3f3fb, #fff);
                box-shadow: 2px 2px 15px var(--bluegray-50);
                border-radius: 8px;

                .p-dropdown-label.p-inputtext {
                    height: 42px;
                    color: var(--bluegray-600);
                    padding-top: 0;
                    padding-bottom: 1px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding-left: 22px;
                }

                .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
                    font-size: 12px;
                    color: var(--bluegray-600);
                }
            }
        }
    }

    .body {
        margin-top: 10px;
    }
}
