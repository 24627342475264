.series-stack {
    margin: 20px 8px 24px;
    background: var(--widget-series-stack-bgd);
    border-radius: 8px;
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;

    &>.title {
        position: absolute;
        text-transform: uppercase;
        background: #fff;
        border-radius: 20px;
        font-weight: 500;
        padding: 4px 16px;
        color: var(--widget-series-stack-title);
        top: -12px;
        left: -12px;
        font-size: 14px;
    }

    .p-skeleton {
        margin-top: 16px;
    }

    .series-item {
        height: 90px;
        border-radius: 8px;
        margin: 16px 10px 0;
        position: relative;
        background: rgba(255, 255, 255, 0.09);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(3.9px);
        -webkit-backdrop-filter: blur(3.9px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 17px;

        .title {
            position: absolute;
            top: -10px;
            text-align: center;
            left: 0;
            right: 0;

            span {
                background: var(--widget-series-item-title-color);
                color: var(--widget-series-item-title-bgd);
                // border-radius: 14px;
                font-size: 11px;
                text-transform: uppercase;
                font-weight: 500;
                padding: 3px 8px;
                box-shadow: 1px 1px 15px var(--widget-series-item-title-color);
            }
        }

        .icon {
            width: 54px;
            height: 54px;

            img {
                width: 54px;
                filter: drop-shadow(3px 0 0 white)
                        drop-shadow(0 3px 0 white)
                        drop-shadow(-3px 0 0 white)
                        drop-shadow(0 -3px 0 white);
            }
        }

        .status {
            text-align: center;
            padding-bottom: 12px;

            .established {
                font-size: 12px;
                color: var(--widget-series-status-established);
            }

            .date {
                color: var(--widget-series-status-date);
                font-weight: 600;
                font-size: 16px;
            }

            .current {
                position: absolute;
                bottom: 9px;
                left: 0;
                right: 0;
                text-align: center;
                font-size: 13px;
                color: var(--widget-series-status-current);

                span {
                    font-weight: 600;
                }
            }
        }

        .value {
            height: 82px;
            width: 56px;
            margin-top: -18px;
            border-radius: 5px;
            background: var(--widget-series-value-bgd);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: var(--widget-series-value-color);

            div {
                font-size: 36px;
                line-height: 34px;
            }

            span {
                font-size: 11px;
                color: var(--widget-series-value-span);
            }
        }

        .flash {
            font-size: 10px;
            background: var(--orange-50);
            color: var(--orange-500);
            padding: 1px 5px 1px 3px;
            position: absolute;
            bottom: 7px;
            right: 32px;
            animation: pulse 5s infinite;

            .pi {
                font-size: 10px;
            }
        }

        @keyframes pulse {
        	0% {
        		// transform: scale(0.95);
        		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
                opacity: 0;
        	}

        	70% {
        		// transform: scale(1);
        		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
                opacity: 1;
        	}

        	100% {
        		// transform: scale(0.95);
        		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                opacity: 0;
        	}
        }
    }
}
