.layout-desktop {

    .mobile-matches {
        flex-direction: row;
        padding-top: 0px;
        min-height: unset;
        margin-left: 0;

        .mobile-matches_section {
            width: 50%;

            .splide__slide {

                margin-right: unset !important;
                margin-left: 10px;
            }
        }
    }
}

.mobile-matches {
    position: relative;
    z-index: 2;
    min-height: 500px;
    background: #fff;
    margin: 0px 8px;
    border-radius: 12px;
    box-sizing: border-box;
    padding-top: 30px;
    display: flex;
    flex-direction: column;

    .mobile-matches_section {
        margin: 1.5rem .5rem 1rem;
        background: var(--matches-carousel-gradient);
        height: 254px;
        border-radius: 12px;
        position: relative;

        .holder {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--matches-carousel-split);

            .pi {
                font-size: 30px;
            }

            &>div {
                font-size: 14px;
                font-weight: 300;
                margin-top: 8px;
            }
        }

        &.list-mode {
            height: unset;
        }

        .mode-switch {
            position: absolute;
            z-index: 10;
            right: 8px;
            top: -17px;
            background: var(--matches-carousel-deep);
            height: 30px;
            width: 64px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.35s linear;

            span {
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.35s ease-in-out;
                color: var(--matches-carousel-split);
                border-radius: 50%;

                &:hover {
                    cursor: pointer;
                    opacity: .78;
                }

                &.selected {
                    // color: var(--blue-50);
                    color: #fff;

                    &:hover {
                        cursor: default;
                        opacity: 1;
                    }
                }

                &.enabled {
                    color: #fff;

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
        }

        .title {
            text-transform: uppercase;
            box-sizing: border-box;
            padding: 0 20px 4px;
            border-radius: 15px;
            position: absolute;
            top: -12px;
            background: #fff;
            left: -8px;
            font-size: 14px;
            color: var(--bluegray-800);
        }

        .splide__pagination {
            bottom: -9px;
            justify-content: flex-start;
            padding: 0 14px;
            flex-wrap: nowrap;
            overflow: hidden;

            .splide__pagination__page {
                width: 10px;
                border-radius: 5px;
                background: var(--matches-carousel-deep);
                opacity: 1;
                height: 7px;
            }

            .splide__pagination__page.is-active {
                width: 17px;
                border-radius: 5px;
                margin-left: 5px;
                margin-right: 5px;
                background: var(--matches-carousel-color);
                opacity: 1;
            }
        }

        .mobile-matches_item {
            border-radius: 6px;
            margin: 16px 0px 14px;
            position: relative;
            background: rgba(255, 255, 255, 0.09);
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(3.9px);
            -webkit-backdrop-filter: blur(3.9px);
            transition: all .4s ease-in-out;

            &:hover {
                cursor: pointer;
                background: rgba(255, 255, 255, 0.03);
                -webkit-backdrop-filter: blur(2px);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }

            .mobile-match_card {
                height: 200px;
                display: flex;
                flex-direction: column;
                padding: 8px;
                margin-top: 22px;
                box-sizing: border-box;
                justify-content: space-between;

                .teams {

                    .emblems {
                        display: flex;
                        align-items: center;

                        span {
                            margin: 0 5px;
                            font-size: 11px;
                            color: var(--matches-carousel-color);
                        }
                    }

                    .names {
                        margin-top: 8px;
                        text-transform: uppercase;
                        color: var(--matches-carousel-color);
                        font-size: 13px;
                        font-weight: 400;

                        i {
                            font-style: normal;
                            color: var(--matches-carousel-split);
                            font-weight: 300;
                        }

                        div {

                            &:first-child {
                                margin-bottom: 2px;
                            }
                        }
                    }
                }

                .score {
                    font-weight: 500;
                    font-size: 30px;
                    color: #fff;
                    margin-bottom: 20px;

                    i {
                        font-style: normal;
                        margin: 0 3px;
                        font-size: 26px;
                    }
                }

                .meta {

                    .time {
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--matches-carousel-color);
                    }

                    .date {
                        font-size: 12px;
                        color: var(--matches-carousel-muted);
                    }

                    .tournament-name {
                        font-weight: 300;
                        color: var(--matches-carousel-muted);
                        font-size: 10px;
                        margin-top: 6px;
                    }
                }
            }
        }

        .mobile-matches_list {
            padding-top: 14px;

            .mobile-matches_list-item {
                height: 34px;
                font-size: 13px;
                box-sizing: border-box;
                padding: 0 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: all .35s ease-in-out;

                &:hover {
                    cursor: pointer;
                    opacity: .68;
                }

                &:nth-child(even) {
                    background: rgba(255, 255, 255, 0.03);
                    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.02);
                    backdrop-filter: blur(3.9px);
                    -webkit-backdrop-filter: blur(3.9px);
                }

                &:last-child {
                    box-shadow: none;
                    backdrop-filter: none;
                    -webkit-backdrop-filter: none;
                }

                .mean {
                    width: 50px;

                    span {
                        display: block;
                        font-weight: 400;
                        color: var(--matches-carousel-split);

                        &.date {
                            font-size: 11px;
                        }

                        &.time {
                            font-size: 11px;
                            line-height: 11px;
                            font-weight: 600;
                        }
                    }
                }

                .teams {
                    font-weight: 300;
                    color: var(--mobile-matches-list-teams);
                    display: flex;
                    align-items: center;
                    padding-left: 8px;
                    justify-content: flex-start;
                    width: calc(100% - 76px);
                    flex-wrap: wrap;

                    span {
                        white-space: nowrap;
                    }

                    .pi {
                        font-size: 9px;
                        margin: 0 4px;
                        color: var(--mobile-matches-list-cross);
                        padding-top: 3px;
                    }
                }

                .score {
                    font-weight: 500;
                    color: #fff;
                }
            }
        }
    }
}
