.event-icon {
    -webkit-mask-size: cover;

    &.goal {
        -webkit-mask-image: url('./ball.png');
    }

    &.owngoal {
        -webkit-mask-image: url('./ball.png');
        background: var(--red-600) !important;
    }

    &.penalty {
        -webkit-mask-image: url('./penalty.png');
    }

    &.missedPenalty {
        -webkit-mask-image: url('./penalty.png');
        background: var(--red-600) !important;
    }

    &.yc {
        border-radius: 15%;
        background: var(--yellow-500) !important;
        box-shadow: 0px 4px 5px 2px var(--yellow-50);
    }

    &.syc {
        border-radius: 15%;
        background: var(--yellow-500) !important;
        box-shadow: 0px 4px 5px 2px var(--red-50);
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            top: 0;
            bottom: 0;
            right: 0;
            width: 50%;
            position: absolute;
            background: var(--red-500) !important;
        }
    }

    &.rc {
        border-radius: 15%;
        background: var(--red-500) !important;
        box-shadow: 0px 4px 5px 2px var(--red-50);
    }
}
