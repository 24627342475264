.non-ideal-state {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message {
        padding: 20px;
        font-size: 14px;
        color: var(--bluegray-400);
    }
}
