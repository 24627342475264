.footer {
    display: flex;

    .footer_socials {
        display: flex;

        .footer_socials_item {
            width: 38px;
            height: 38px;
            margin: 9px;
            border-radius: 50%;
            overflow: hidden;
            transition: all 0.3s ease-in-out;


            &:hover {
                cursor: pointer;
                opacity: 0.78;
            }
        }
    }

    .footer_contacts {

        .footer_contacts_content {
            font-size: 12px;
            letter-spacing: 0.02em;
        }
    }

    .footer-banners {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        align-items: flex-start;
        max-width: 33.3%;
        height: 100%;
        padding-top: 1.5rem;

        img {
            width: 142px;
            border-radius: .5rem;
            box-shadow: 1px 1px 1rem 0px rgba(0, 0, 0, .04);
        }
    }

    &.desktop {

        .container {
            position: relative;
            margin: 0 auto;
            width: 1280px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 30px;
            padding-bottom: 30px;

            @media screen and (min-width: 1600px) {
                width: 1440px;
            }

            .footer_contacts {
                width: 50%;
                display: flex;
                font-size: 14px;

                &>div {
                    width: 50%;

                    &:last-child {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;

                        &>div {
                            margin-bottom: 12px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }

                .footer_contacts_emblem {

                    img {
                        max-width: 120px;
                        height: 80px;
                        object-fit: contain;
                    }
                }
            }

            .desc {
                margin-bottom: .65rem;
                aspect-ratio: unset !important;
            }

            .store_btn {
                margin-bottom: 12px;
            }
        }
    }

    &.mobile {
        padding: 0 0 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .installTrigger {
            margin: 1.5rem 1.5rem 0;
            display: flex;
            align-items: center;
            background: var(--achievement-widget-gradient);
            border-radius: .75rem;
            box-shadow: 1px 2px 10rem 0px rgba(0, 0, 0, .14);
            width: calc(100% - 3rem);
            box-sizing: border-box;
            padding: 1rem 2rem;

            .vendor {
                margin-right: 2rem;

                i {
                    font-size: 4rem;
                    color: var(--achievement-name-color);
                }
            }

            .description {
                width: calc(100% - 6rem);
                font-size: 1.5rem;
                text-align: center;
                box-sizing: border-box;
                padding-right: 1rem;
                color: var(--achievement-description-color);
            }
        }

        .footer-banners {
            display: flex;
            gap: 1rem;
            justify-content: center;
            box-sizing: border-box;
            padding: 0 1rem 1.5rem;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 100%;
            max-width: unset;

            img {
                width: 100px;
                border-radius: .5rem;
                box-shadow: 1px 1px 1rem 0px rgba(0, 0, 0, .04);
            }
        }

        .footer_socials {
            margin-top: 20px;
            margin-bottom: 16px;
        }

        .desc {
            margin-bottom: .75rem;
            font-size: 1.2rem;
        }

        .footer_contacts {
            display: flex;
            box-sizing: border-box;
            padding: 0 20px;

            .footer_contacts_content {

                &>div {
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 5px;

                    a {
                        color: var(--blue-800);
                    }
                }

                &>div:last-child {
                    font-weight: 500;
                }
            }

            .footer_contacts_emblem {
                margin-right: 20px;

                img {
                    width: 64px;
                }
            }
        }
    }
}
