.emblem {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.backdroped {
        background: #fff;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 2;
            background-image: url('./backdroped_cover.png');
            background-size: cover;
        }
    }

    &.xl {
        width: 128px;
        height: 128px;
        border-radius: 14px;
    }

    &.lg {
        width: 80px;
        height: 80px;
        border-radius: 12px;
    }

    &.md {
        width: 64px;
        height: 64px;
        border-radius: 10px;
    }

    &.sm {
        width: 44px;
        height: 44px;
        border-radius: 8px;
    }

    &.xs {
        width: 28px;
        height: 28px;
        border-radius: 6px;
    }

    &.xxs {
        width: 20px;
        height: 20px;
        border-radius: 6px;
    }

    img {
        height: 100%;
    }

    &.player {
        overflow: hidden;
        align-items: flex-start;

        img {
            width: 100%;
            height: auto;
        }
    }
}
