.items-nav {
    height: 70px;
    width: calc(100% + 40px);
    overflow: hidden;
    margin: 0 -20px;
    padding: 0 40px;

    .items-nav_item {
        font-size: 13px;
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: .6;
        }

        .title {
            margin-top: 3px;
            color: var(--bluegray-600);
        }

        .p-tag {
            font-size: 9px;
            background: var(--global-tag-border-color);
            color: var(--global-tag-value);
        }
    }

    .splide__slide {
        opacity: 0.8;
        transition: all .15s ease-in-out;

        &.is-active {
            opacity: 1;
        }
    }

    .splide__arrow--prev {
        left: -33px;
        background: none;

        svg {
            fill: var(--global-tag-value);
        }
    }

    .splide__arrow--next {
        right: -33px;
        background: none;

        svg {
            fill: var(--global-tag-value);
        }
    }
}
