.fantasy-announcement {
    display: flex;
    align-items: center;
    margin: 1rem -15px 0;

    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--bluegray-800);
    }

    @media screen and (min-width: 768px) {
        margin: 0;
        margin-top: 2rem;

        .description {
            padding-left: .75rem;
            font-size: .9rem;
        }
    }
}

.fantasy_match-widget {
    display: flex;
    flex-direction: column;
    align-items: center;

    .fantasy_match-widget_brand {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        img {
            height: 60px;
            margin-right: 4px;
        }

        span {
            font-size: 22px;
            text-transform: uppercase;
            margin-left: 4px;
            padding-top: 2px;
            font-weight: 300;
            letter-spacing: 0.02em;
            text-shadow: 0px 2px 1px var(--fantasy-text-shadow);
        }
    }

    .fantasy_match-widget_brand_label {
        text-transform: uppercase;
        font-style: italic;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 .75rem .2rem .35rem;
        position: relative;
        margin-right: .5rem;
        margin-top: .2rem;

        span {
            font-size: 16px;
            position: relative;
            text-shadow: none;
            font-weight: 500;
            color: var(--fantasy-label-color);
        }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: var(--fantasy-label-background);
            transform: skewX(-10deg);
            border-radius: .75rem;
        }
    }

    .fantasy_match-widget_values {
        width: 100%;
        margin-top: 3px;
        position: relative;

        .fantasy_match-widget_values_stripes {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 8px;
            left: 0;
            right: 0;

            &>div {
                height: 3px;
                width: calc(100% - 60px);
                margin-bottom: 6px;
                border-radius: 3px;
                background: var(--bluegray-800);
                box-shadow: 0px 4px 20px 5px var(--fantasy-stripes-shadow);

                &:nth-child(2) {
                    width: 100%;
                }
            }
        }

        .fantasy_match-widget_values_body {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;

            .fantasy_match-widget_values_item {
                width: 70px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &>div {
                    width: 56px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--bluegray-50);
                    background: var(--bluegray-800);
                    font-weight: 500;
                    letter-spacing: 0.03em;
                    border-radius: 12px;
                    margin-bottom: 6px;
                }

                &>span {
                    font-weight: 500;
                    text-transform: uppercase;
                    color: var(--bluegray-700);
                }
            }
        }
    }

    &.mobile {
        padding: 0px 40px;
    }

    &.widget {

        .fantasy_match-widget_brand {

            img {
                height: 40px;
            }

            span {
                font-size: 16px;
            }
        }

        .fantasy_match-widget_values {

            .fantasy_match-widget_values_stripes {
                top: 3px;

                &>div {
                    height: 2px;
                    margin-bottom: 5px;
                }
            }

            .fantasy_match-widget_values_body {

                .fantasy_match-widget_values_item {
                    width: 50px;

                    &>div {
                        width: 40px;
                        height: 21px;
                        border-radius: 8px;
                        font-size: 11px;
                        margin-bottom: 4px;
                    }

                    &>span {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
