.position-map {
    margin-top: 30px;

    .title {
        text-transform: uppercase;
        color: var(--bluegray-800);
        font-weight: 500;
        margin-bottom: 20px;
    }

    .lineup {

        &::before {
            background: var(--statsblock-lineup-line);
        }
    }
}
