.stats-content {
    border: 1px solid #e4edfd;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 10px;

    .stats-row {
        padding: 12px 30px;

        .stats-label {
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            font-weight: 500;
            color: var(--bluegray-600);

            .qty {
                color: var(--match-stats-qty);
            }
        }

        .stats-bar {
            margin-top: 6px;
            height: 10px;
            border-radius: 5px;
            background: var(--match-stats-bar);
            box-shadow: 1px 1px 12px rgba(59, 130, 246, 0.11);

            .stats-value {
                height: 10px;
                border-radius: 5px;
                background: var(--match-stats-value);
                transition: all .35s ease-in-out;
            }
        }
    }
}
