.multi-standings {
    padding-left: 24px;

  .p-selectbutton.p-buttonset {
    height: 44px;
    display: flex;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    .p-button {
      border-color: var(--surface-border);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -3px;
      font-size: 12px;
      height: 42px;
      color: var(--bluegray-600);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.p-highlight {
        color: white;
        background-color: var(--bluegray-600);
      }

      &.p-highlight:hover {
        border-color: var(--surface-border);
      }
    }
  }

    .p-skeleton {
        background-color: #eef2f7;
        border-radius: 15px;
    }

    .content-body {
        margin-top: 12px;
    }

    .p-dropdown {
        width: 100%;
        border-top: 0;
        border-color: var(--surface-border);
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 44px;
        font-size: 14px;

        .p-dropdown-label.p-inputtext {
            height: 42px;
            color: var(--bluegray-600);
            padding-top: 0;
            padding-bottom: 1px;
            display: flex;
            align-items: center;
            font-size: 14px;
            padding-left: 22px;
        }

        .p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
            font-size: 12px;
            color: var(--bluegray-600);
        }

        &.p-dropdown:not(.p-disabled).p-focus, &.p-dropdown:not(.p-disabled):hover {
            border-color: var(--surface-border);
        }

        &.p-dropdown:not(.p-disabled):hover {
            border-color: var(--surface-border);
        }

        &.p-focus {
            outline: none;
            box-shadow: none !important;
        }
    }

    .content-body {
        margin-top: 0;

        .standings {
            background: transparent !important;

            .standings-head {
                height: 48px;
                font-size: 13px;
                color: var(--bluegray-500);
                background: transparent !important;
            }

            .standings-row {
                position: relative;
                border-top: none !important;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 5px;
                    height: 1px;
                    background: linear-gradient(to left, #e7f0ff, transparent);
                    content: '';
                }

                &.highlight {
                    background: linear-gradient(to bottom, var(--blue-300), var(--blue-500)) !important;
                    color: var(--blue-50);
                    border-radius: 8px;
                }
            }

            .standings-row .standings-row_team .__pos {
                width: 36px;
            }
        }
    }
}
