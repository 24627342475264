.mobile-legs {

    .mobile-legs_cards {
        margin: 1rem 0 2rem;
    }

    .mobile-legs_card {
        border-radius: .75rem;
        background: var(--blue-50);
        margin: 0 1rem;
        padding: 1rem;
        height: 100%;
    }

    .playoff-node {
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: .75rem;
        margin-bottom: .8rem;
        box-shadow: 1px 1px 25px rgb(59 130 246 / 11%);
        position: relative;

        .shootout {
            position: absolute;
            right: 10px;
            top: calc(50% - .7rem);
            background: #fff;
            padding: .2rem .5rem;
            border-radius: .6rem;
            border: 1px solid var(--blue-50);
            font-size: .8rem;

        }

        &:last-child {
            margin-bottom: 0;
        }

        .row {
            display: flex;
            height: 44px;
            align-items: center;
            padding: 0 16px;

            &:first-child {

                .name {
                    border-bottom: 1px solid var(--blue-50);
                }
            }

            .emb {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                img {
                    height: 30px;
                }
            }

            .name {
                width: 100%;
                height: 44px;
                line-height: 44px;
                text-align: left;
                box-sizing: border-box;
                padding-left: 12px;
                padding-right: 12px;
                font-size: 14px;
            }

            .score {
                width: 18px;
                text-align: right;
                font-weight: 500;
            }
        }
    }
}
