.matches-carousel {

    .matches-carousel_list {
        display: flex;
        width: 50%;
        flex-direction: column;

        .matches-carousel_list_item {
            width: 100%;
            padding: 2px 14px 0;
            box-sizing: border-box;
            align-items: center;
            height: 30px;
            display: flex;
            text-decoration: none;
            color: initial;

            &:last-child {
                border-bottom: none;
            }

            &:nth-child(even) {
                background: rgba(0, 0, 0, .01);
            }

            .matches-carousel_list_item_datetime {
                width: 80px;
                font-size: 11px;
                line-height: 17px;
                text-align: right;
                opacity: 0.5;
            }

            .matches-carousel_list_item_info {
                display: flex;
                justify-content: space-between;
                width: calc(100% - 80px);
                padding-left: 20px;
                box-sizing: border-box;

                .matches-carousel_list_item_teams {
                    font-size: 11px;
                    line-height: 17px;

                    span {
                        margin: 0 4px;
                        opacity: 0.5;
                    }
                }

                .matches-carousel_list_item_score {
                    font-size: 11px;

                    &.__played {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    &.desktop {

        &.extended {

            .matches-carousel_list {
                flex-direction: row;
                flex-wrap: wrap;
                padding-bottom: 16px;
                padding: 0 14px;

                .matches-carousel_list_item {
                    position: relative;
                    color: var(--blue-900);

                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        height: 1px;
                        content: "";
                        background: linear-gradient(to right, transparent, var(--blue-100));
                        bottom: 0;
                    }

                    &:last-child {
                        // margin-bottom: 4px;

                        // &::after {
                        //     display: none;
                        // }
                    }
                }

                &.upcoming {
                    .matches-carousel_list_item {

                        &::after {
                            background: linear-gradient(to right, transparent, var(--blue-100));
                        }
                    }
                }
            }

            .container {
                padding-top: 10px;
                width: 1280px;
                flex-direction: column-reverse;

                @media screen and (min-width: 1600px) {
                    width: 1440px;
                }

                &::before, &::after {
                    content: none;
                }

                .matches-carousel_list {
                    width: 100%;
                    // column-count: 2;

                    .matches-carousel_list_item {
                        width: 50%;
                        transition: all .4s ease-in-out;

                        &:hover {
                            cursor: pointer;
                            opacity: .6;
                        }

                        &:nth-child(even) {
                            background: transparent;

                            &:after {
                                background: linear-gradient(to left, transparent, var(--blue-100));
                            }
                        }
                    }
                }
            }
        }

        .container {
            position: relative;
            margin: 0 auto;
            width: 1480px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            padding-top: 30px;
            // padding-bottom: 10px;
            position: relative;

            @media screen and (min-width: 1600px) {
                width: 1640px;
            }

            .matches-carousel_cards {
                margin-top: 20px;

                .match-card {

                    .emblem {
                        box-shadow: 0px 2px 20px rgb(0 0 0 / 5%);
                        box-sizing: border-box;
                        padding: 10px;
                    }
                }

                .skeleton {
                    display: flex;
                    width: 1480px;
                    position: relative;
                    margin: 0 auto;
                    justify-content: space-between;
                    padding-top: 10px;

                    @media screen and (min-width: 1600px) {
                        width: 1640px;
                    }

                    .p-skeleton {
                        background-color: '#e7eef7';
                    }
                }
            }

            &::before, &::after {
                content: '';
                position: absolute;
                width: 100px;
                height: 140px;
                top: 88px;
                z-index: 10
            }

            &::before {
                left: 0px;
                background: linear-gradient(to left, transparent, #fff)
            }

            &::after {
                right: 0px;
                background: linear-gradient(to right, transparent, #fff)
            }
        }
    }

    &.mobile {
        padding: 17px 0 29px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.extended {
            padding-top: 0;
            padding-bottom: 0;

            &>div.matches-carousel_cards {
                margin-bottom: 20px;
                margin-top: 10px;
            }
        }

        &>div {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &>div.matches-carousel_cards {
            margin-top: 17px;
            overflow: hidden;
            max-width: 100%;

            .splide__slide {
                opacity: 0.3;
                transition: all 0.35s ease-in-out;

                &.is-active {
                    opacity: 1;
                }
            }
        }

        &>div.btn-pull {
            margin-top: 7px;
        }
    }
}
