.standings-skeleton {
    padding: 18px 18px 24px;

    .standings-skeleton_row {
        display: flex;
        justify-content: space-between;
        height: 32px;
        align-items: center;

        .p-skeleton {
            background: #f0f3f7;
        }
    }
}
