.career {
    z-index: 2;
    position: relative;
    padding: 0 8px;
    margin: -8px 8px -20px;
    background: #fff;
    border-radius: 12px;
    min-height: 57vh;

    .career-card {
        background: #fff;
        padding: 20px;
        border-radius: 12px;
        margin-bottom: 12px;
        box-shadow: 2px 2px 8px var(--blue-50);

        &:first-child {
            padding-top: 36px;
        }

        .title {
            color: var(--bluegray-700);
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            position: relative;
            padding-bottom: 10px;

            .emblem {
                margin-right: 8px;
            }

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                height: 1px;
                content: '';
                background: linear-gradient(to right, transparent, var(--blue-100));
                bottom: 0;
            }

            .range {
                position: absolute;
                font-size: 12px;
                background: var(--green-50);
                border-radius: 12px;
                right: 0;
                padding: 5px 13px;
                font-weight: 300;

                &.ended {
                    background: var(--orange-50);
                }
            }
        }

        .career-body {

            .p-dropdown {
                width: 100%;
                margin-top: 8px;
                border-color: var(--blue-100);

                .p-inputtext {
                    font-size: 13px;
                    padding: 10px 16px;
                }

                .pi {
                    font-size: 13px;
                }
            }

            .values {
                display: flex;
                flex-wrap: wrap;
                padding-left: 12px;

                .values-item {
                    width: 25%;
                    margin-top: 18px;

                    &:nth-child(3) {
                        width: 50%;
                    }

                    & > span {
                        font-weight: 300;
                        font-size: 13px;
                    }

                    & > div {
                        font-size: 20px;
                        font-weight: 600;
                        color: var(--bluegray-700);
                    }
                }
            }
        }
    }
}
